export function createCancelTokenHandler($axios, requestNames) {
  // initializing the cancel token handler object
  const cancelTokenHandler = {};
  const CancelToken = $axios.CancelToken;

  requestNames.forEach((name) => {
    // initializing the cancel token of the request
    const cancelTokenRequestHandler = {
      cancelToken: undefined
    };

    // associating the cancel token handler to the request name
    cancelTokenHandler[name] = {
      handleRequestCancellation: () => {
        // if a previous cancel token exists,
        // cancel the request
        cancelTokenRequestHandler.cancelToken &&
          cancelTokenRequestHandler.cancelToken.cancel(`${name} canceled`);

        // creating a new cancel token
        cancelTokenRequestHandler.cancelToken = CancelToken.source();

        // returning the new cancel token
        return cancelTokenRequestHandler.cancelToken;
      }
    };
  });

  return cancelTokenHandler;
}
