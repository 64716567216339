import MetaApi from './meta.api';
import { META_ENTITY_TYPE } from './meta.const';

class MetaService {
  constructor(client, $config) {
    this.api = new MetaApi(client, $config);
  }

  /**
   * @param {MetaRequest} params
   * @returns {Promise<MetaResponse>}
   */
  get(params) {
    return this.api.getMeta(params);
  }

  /**
   * @param {string} slug
   * @returns {Promise<MetaResponse>}
   */
  getDoctorMetaBySlug(slug) {
    return this.get({
      slug,
      type: META_ENTITY_TYPE.DOCTOR
    });
  }
}

export default MetaService;
