import amplitude from 'amplitude-js';
import { getAge } from '../../helpers';

class AmplitudeService {
  AMPLITUDE_KEY = null;
  amplitudeInstance = null;
  isUserInitiated = false;
  $auth = null;

  constructor(key, $auth) {
    this.$auth = $auth;
    this.amplitudeInstance = amplitude.getInstance();
    this.amplitudeInstance.init(key, null, {
      includeReferrer: true,
      includeUtm: true
    });
  }

  logEvent(...params) {
    if (!this.amplitudeInstance) {
      return;
    }

    if (this.isUserInitiated && !this.$auth.loggedIn) {
      this.amplitudeInstance.setUserId(null);
      this.amplitudeInstance.setUserProperties();
      this.isUserInitiated = false;
    }

    if (
      !this.isUserInitiated &&
      this.$auth.loggedIn &&
      Object.keys(this.$auth.user).length
    ) {
      const { birthDate, sex, id } = this.$auth.user;
      const age = getAge(birthDate);
      const gender = sex ? 'мужской' : 'женский';

      this.amplitudeInstance.setUserId(id);
      this.amplitudeInstance.setUserProperties({
        age,
        gender
      });
      this.isUserInitiated = true;
    }

    this.amplitudeInstance.logEvent(...params);
  }
}

export default AmplitudeService;
