import { v4 as uuidv4 } from 'uuid';
import DoctorApi from './doctor.api';
import { createBlobFromArrayBuffer } from '~/services/files/files.helpers';

class DoctorService {
  constructor(client, $config, deps) {
    this.api = new DoctorApi(client, $config);
    this.metaService = deps.metaService;
  }

  /**
   * @param {string} slug
   * @returns {Promise<MetaResponse>}
   */
  getMetaBySlug(slug) {
    return this.metaService.getDoctorMetaBySlug(slug);
  }

  /**
   * @param {number} id
   * @returns {Promise<DoctorProfileResponse>}
   */
  getProfile(id) {
    return this.api.getProfile(id);
  }

  addToFavorite(doctorId) {
    return this.api.addToFavorite(doctorId);
  }

  removeFromFavorite(doctorId) {
    return this.api.removeFromFavorite(doctorId);
  }

  /**
   * @param {number} doctorId
   * @param {string} orderBy
   */
  listReviews(doctorId, orderBy) {
    return this.api.listReviews(doctorId, orderBy);
  }

  /**
   * @param {string} key
   */
  async getFile(key) {
    const file = await this.api.getFile(key);

    return {
      blob: createBlobFromArrayBuffer(file, 'application/pdf'),
      OriginName: uuidv4()
    };
  }

  /**
   * @param {string} doctor_id
   * @param {string} type
   */
  async getRegaliaFiles(doctor_id, type) {
    const file = await this.api.getRegaliaFiles(doctor_id, type);

    return {
      blob: createBlobFromArrayBuffer(file, 'application/pdf'),
      OriginName: uuidv4()
    };
  }
}

export default DoctorService;
