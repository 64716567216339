import { Api } from '~/api';

class DoctorsApi extends Api {
  constructor(client, $config) {
    const baseUrl = $config.APP_NEW_API + '/v3';
    super(client, baseUrl);

    this.baseURL = baseUrl;
  }

  async getDoctors(params = {}) {
    try {
      const { data } = await this.post(`/doctor-search/doctor/list`, params);
      return data?.data;
    } catch (e) {
      return null;
    }
  }
}

export default DoctorsApi;
