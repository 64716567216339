import { format } from '@evd3v/date-fns';

export const convertBytesToMb = (b) => {
  const kb = b / 1000;
  const mb = kb / 1000;
  if (b < 1000) return `${b} B`;
  if (kb < 1000) return `${kb.toFixed(2)} KB`;
  return `${mb.toFixed(2)} MB`;
};

export const convertBlobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);

  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export const DataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(',');
  const byteString = splitDataURI[0].includes('base64')
    ? atob(splitDataURI[1])
    : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
};

export const downloadFile = (file, name) => {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(file);
  link.download = name; // generateTimestampFileName(name ?? 'file');
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  setTimeout(() => {
    window.URL.revokeObjectURL(link.href);
  }, 100);
};

export const generateTimestampFileName = (prefix) => {
  return `${prefix}-${format(new Date(), 'dd-MM-yyyy_HH-mm')}`;
};

export const createBlobFromArrayBuffer = (buffer, mimeType) => {
  return new Blob([buffer], { type: mimeType });
};

export const arrayBufferToObject = (buffer) => {
  return JSON.parse(new TextDecoder().decode(buffer));
};

export const getExtension = (type) => {
  return type?.split('/')[1].toUpperCase();
};

export const convertDate = (date) => {
  return `${format(date, 'yy.MM.dd')} в ${format(date, 'HH:mm')}`;
};

export const choseFile = () => {
  return new Promise((resolve) => {
    const filesList = [];
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = true;
    fileInput.style.display = 'none';

    fileInput.addEventListener('change', (event) => {
      let files = event.target.files;
      files = Array.from(files);
      if (files.length > 0) {
        files = files.map((file) => {
          return {
            type: 'File',
            name: file.name,
            size: convertBytesToMb(file.size),
            extension: file.name.split('.').pop(),
            date: convertDate(new Date()),
            file,
            isNew: true,
            rightIcon: 'm/delet'
          };
        });
        filesList.push(...files);
      }
      document.body.removeChild(fileInput);
      resolve(filesList);
    });

    document.body.appendChild(fileInput);
    fileInput.click();
  });
};

export const saveOrOpenBlob = (url, onprogress) => {
  let xmlHTTP = '';
  let isError = false;
  let isProgressVisible = false;
  return new Promise((resolve, reject) => {
    xmlHTTP = new XMLHttpRequest();
    isError = false;
    let notifiedNotComputable = false;
    xmlHTTP.open('GET', url, true);
    xmlHTTP.responseType = 'arraybuffer';
    isProgressVisible = true;
    xmlHTTP.onprogress = function(pr) {
      if (pr.lengthComputable) {
        onprogress((pr.loaded / pr.total) * 100);
      } else if (!notifiedNotComputable) {
        notifiedNotComputable = true;
        onprogress(-1);
      }
    };
    const xml = xmlHTTP;
    xmlHTTP.onloadend = function() {
      if (!xml.status.toString().match(/^2/)) {
        reject(xml);
        onprogress(-1);
        return;
      }

      const options = {};
      const headers = xml.getAllResponseHeaders();
      const m = headers.match(/^Content-Type\s*(.*?)$/im);

      if (m && m[1]) {
        options.type = m[1];
      }
      const file = {
        url: window.URL.createObjectURL(new Blob([this.response], options)),
        xmlHTTP,
        isError,
        isProgressVisible
      };
      resolve(file);
    };
    xmlHTTP.send();
  });
};

export const filterOptions = ({
  message,
  options,
  filterOptions,
  isProgressVisible,
  isPreview
}) => {
  if (message.isSending)
    return Object.values(options).filter((item) =>
      Object.values(filterOptions.isSending.keys).includes(item.key)
    );
  else if (message.isError)
    return Object.values(options).filter((item) =>
      Object.values(filterOptions.isError.keys).includes(item.key)
    );
  else if (isProgressVisible)
    return Object.values(options).filter((item) =>
      Object.values(filterOptions.isDownloading.keys).includes(item.key)
    );
  else if (
    (message.messageType.toLowerCase() === 'image' &&
      isPreview &&
      message.isPreview !== false) ||
    (message.messageType.toLowerCase() === 'file' &&
      message.payload &&
      JSON.parse(message.payload)?.filesInformation[0]?.mimeType?.includes(
        'image'
      ) &&
      isPreview &&
      message.isPreview !== false)
  )
    return Object.values(options).filter((item) =>
      Object.values(filterOptions.defaultPhoto.keys).includes(item.key)
    );
  else
    return Object.values(options).filter((item) =>
      Object.values(filterOptions.default.keys).includes(item.key)
    );
};

export const imageMessageKey = (message, isLoadingError) => {
  if (message.isError) return { key: 'Repeat' };
  if (isLoadingError) return { key: 'Download' };
  else return { key: 'Download' };
};

export const fileMessageKey = (message) => {
  if (message.isError) return { key: 'Repeat' };
  else return { key: 'Download-to-device' };
};
