import SupportChatService from '@/services/support-chat/support.service';

export default function(appData, inject) {
  const {
    app,
    $axiosClient,
    $hubClient,
    $config,
    store,
    $filesService
  } = appData;
  const client = $axiosClient.instance;
  const supportChatService = new SupportChatService(
    app,
    client,
    $hubClient,
    $config,
    store,
    $filesService
  );
  inject('supportChatService', supportChatService);
}
