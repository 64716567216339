export const STATUSES = {
  IN_WORK: 'inWork',
  CANCELED: 'canceled',
  CLOSED: 'closed',
  CREATED: 'created',
  CURRENT: 'current'
};

export const TYPE_EVENTS = {
  CONSULTATION: 'consultation',
  DM: 'remoteMonitoringTask',
  REFERRAL: 'referral'
};

export const INITIATOR = {
  PATIENT: 'Patient',
  DOCTOR: 'Doctor'
};
