import { Api } from '~/api';

class ReferralConsultationsApi extends Api {
  constructor(client, $config) {
    const baseUrl = $config.APP_NEW_API + '/v3';
    super(client, baseUrl);
    this.baseURL = baseUrl;
  }

  async getReferral(referralId) {
    const { data } = await this.get(`/referral/consultation-referral/info`, {
      params: {
        referralId
      }
    });

    return data;
  }

  async declineRecommendation(id) {
    await this.post(
      '/referral/consultation-referral/recommendation/decline',
      null,
      { params: { id } }
    );
  }
}

export default ReferralConsultationsApi;
