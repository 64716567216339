import {
  format,
  isSameDay,
  parseISO,
  isToday,
  isYesterday
} from '@evd3v/date-fns';
import { MEMBER_TYPE, MESSAGE_TYPE } from './support.const';

export const scrollToBottom = (id) => {
  const el = document.getElementById(id);
  if (el) {
    setTimeout(() => {
      el.scrollTo({
        top: el.scrollHeight,
        behavior: 'smooth'
      });
    }, 0);
  }
};

export const addTimestamps = (messages) => {
  const result = [];
  messages.forEach((current, index, array) => {
    const { createdAt } = current;

    if (index === 0) {
      const timestamp = createTimestamp(createdAt);
      result.push(timestamp);
    } else {
      const previous = array[index - 1];

      const isInSameDay = isSameDay(
        parseISO(previous.createdAt),
        parseISO(createdAt)
      );

      if (!isInSameDay) {
        const timestamp = createTimestamp(createdAt);
        result.push(timestamp);
      }
    }

    result.push(current);
  });

  return result;
};

export const createTimestamp = (date) => {
  return {
    messageType: MESSAGE_TYPE.TIMESTAMP,
    payload: formatedDateTimestamp(date)
  };
};

export const formatedDateTimestamp = (date) => {
  date = parseISO(date);
  if (isToday(date)) {
    return 'Сегодня';
  } else if (isYesterday(date)) {
    return 'Вчера';
  } else {
    return format(date, 'dd MMM');
  }
};

export const getUserSupportName = (message) => {
  if (MEMBER_TYPE[message?.member?.memberType] === 'Admin') {
    return message.member?.personData?.firstName;
  }

  return null;
};

export const addWelcomeMessage = (messages) => {
  messages[messages.length - 1].push(
    ...[
      { ...createTimestamp(new Date().toISOString()) },
      {
        createdAt: new Date().toISOString(),
        messageType: MESSAGE_TYPE.SYSTEM,
        text: 'Если у вас есть вопрос, напишите нам и мы ответим'
      }
    ]
  );
  return messages;
};
