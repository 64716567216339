import { Api } from '~/api';

class ConsultationApi extends Api {
  constructor(client, $config) {
    const baseUrl = $config.APP_NEW_API + '/v3/consultation/review';
    super(client, baseUrl);

    this.baseURL = baseUrl;
  }

  createReview(payload) {
    return this.post('/create', payload);
  }

  editReview(payload) {
    return this.post('/edit', payload);
  }
}

export default ConsultationApi;
