import AuthApi from '@/services/auth/auth.api';

class AuthService {
  constructor(client, $config, $auth, $store, $recaptcha, $app) {
    this.api = new AuthApi(client, $config);

    this.$store = $store;
    this.$auth = $auth;
    this.$recaptcha = $recaptcha;
    this.$config = $config;
    this.$app = $app;
    this.FILE_API = `${$config.APP_FILE_API}/v1/file/`;
  }

  startAuthorizationFlow() {
    this.$store.dispatch('modules/auth/setAuthorizationFlowState', true);
  }

  toEsiaLogin() {
    return this.api.redirectToEsia();
  }

  async sendSmsCode(phone) {
    await this.$recaptcha.init();

    const token = await this.$recaptcha.execute('login');

    const { nextAttemptTime } = await this.api.sendSmsCode({ phone, token });

    return new Date(nextAttemptTime);
  }

  async authorizeByPhone(payload) {
    const { refreshToken, token } = await this.api.loginByPhone(payload);
    this.$auth.setUserToken(token, refreshToken);
    return true;
  }

  logoutGosuslugi() {
    const logoutUrl = new URL(this.$config.GOSUSLUGI_LOGOUT_URL);

    logoutUrl.searchParams.append('redirect_url', window.location.origin);

    window.location.href = logoutUrl.href;
  }

  createImageUrl(url) {
    const imageUrl = new URL(url);
    imageUrl.searchParams.append('access_token', this.token);
    return imageUrl.toString();
  }

  createChatImageUrl(url) {
    const imageUrl = new URL(this.FILE_API + url);
    imageUrl.searchParams.append('access_token', this.token);
    return imageUrl.toString();
  }

  async logout() {
    await this.$auth.logout();
    this.$store.dispatch('modules/auth/resetState');
    this.$store.dispatch('modules/labs/resetOrderData');
    if (process.client) {
      window.location.href = '/';
    }
  }

  get token() {
    if (!this.$auth.loggedIn) {
      return null;
    }
    return this.$auth.strategy.token.get().slice(7);
  }

  get loggedIn() {
    return this.$auth.loggedIn;
  }

  /** ESIA */
  loginEsia(params) {
    return this.api.loginEsia(params);
  }

  logoutEsia(params) {
    return this.api.logoutEsia(params);
  }
}

export default AuthService;
