import { Api } from '~/api';

class SupportChatApi extends Api {
  constructor(client, $config) {
    const baseUrl = $config.APP_NEW_API + '/v3';
    super(client, baseUrl);
    this.baseURL = baseUrl;
  }

  /*
   * Получает последние сообщения чата поддержки для текущего пользователя
   */
  async getLastMessages() {
    const {
      data: { data: messages }
    } = await this.get('/chat-appeal/messages');

    return messages;
  }

  /*
   * Возвращает Id всех обращения для пользователя за последний год в обратном порядке
   */
  async getAllAppealIds() {
    const {
      data: { data: ids }
    } = await this.get('/chat-appeal');
    return ids;
  }

  /*
   * Получает сообщения чата поддержки для обращения пользователя
   */
  async getMessagesAppelaId(id) {
    return await this.get(`/chat-appeal/messages/${id}`);
  }

  /*
   * Отправляет сообщение в чат поддержки
   */
  async sendMessage(payload) {
    return await this.post(`/chat-appeal/send`, payload);
  }
}

export default SupportChatApi;
