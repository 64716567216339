export const state = () => ({
  isLoading: false,
  visibleModal: false,
  notifications: []
});

export const mutations = {
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications;
  },
  SET_ISLOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_VISIBLEMODAL(state, visibleModal) {
    state.visibleModal = visibleModal;
  }
};

export const actions = {
  async fetchNotifications({ commit }) {
    commit('SET_ISLOADING', true);
    const notifications = await this.$notificationsService.getNotifications();
    commit('SET_NOTIFICATIONS', notifications);
    commit('SET_ISLOADING', false);
  },
  async readNotifications({ dispatch }, id) {
    await this.$notificationsService.readNotification(id);
  },
  async readAllNotifications({ dispatch }, ids) {
    await this.$notificationsService.readNotification(ids);
    dispatch('fetchNotifications');
  },
  async unreadNotifications(ids) {
    await this.$notificationsService.unreadNotifications([ids]);
  },
  async clearAllNotifications({ commit, dispatch }) {
    commit('SET_VISIBLEMODAL', false);
    const ids = this.notifications.map((n) => n.id);
    await this.$notificationsService.clearNotifications(ids);
    dispatch('fetchNotifications');
  }
};
