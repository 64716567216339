import { XVCProjectEnum } from '../../consts';
import { Api } from '~/api';

class PolicyApi extends Api {
  constructor(client, $config) {
    const baseUrl = `${$config.APP_NEW_API}/`;
    super(client, baseUrl);

    this.baseURL = baseUrl;
  }

  getProfile() {
    return this.get('patient/current', {
      params: {
        projectType: XVCProjectEnum.DOCTIS
      }
    });
  }

  getPolicy() {
    return this.get('policy/for-person');
  }

  activatePolicy(params) {
    return this.put('policy/activate', params, {
      withoutToast: true
    });
  }
}

export default PolicyApi;
