import { PatientService, DoctorService, MetaService } from '@/services';
import ConsultationReviewService from '@/services/consultation-review/consultation-review.service';
import PolicyService from '@/services/policy/policy.service';
import VtbService from '@/services/vtb/vtb.service';
import AnalysisService from '@/services/analysis/analysis.service';

export default function({ $axiosClient, $config, $authService, i18n }, inject) {
  const client = $axiosClient.instance;

  const metaService = new MetaService(client, $config);

  const patientService = new PatientService(client, $config, i18n);
  const doctorService = new DoctorService(client, $config, { metaService });
  const consultationReviewService = new ConsultationReviewService(
    client,
    $config
  );
  const vtbService = new VtbService(client, $config, i18n);
  const policyService = new PolicyService(client, $config, i18n);
  const analysisService = new AnalysisService(client, $config, i18n);

  inject('patientService', patientService);
  inject('doctorService', doctorService);
  inject('consultationReviewService', consultationReviewService);
  inject('vtbService', vtbService);
  inject('policyService', policyService);
  inject('analysisService', analysisService);
}
