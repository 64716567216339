import { Api } from '~/api';

class ReferralAnalysisApi extends Api {
  constructor(client, $config) {
    const baseUrl = $config.APP_NEW_API + '/v3';
    super(client, baseUrl);
    this.baseURL = baseUrl;
  }

  async getReferral(referralId) {
    const { data } = await this.get(`/referral/laboratory-service/info`, {
      params: {
        referralId
      }
    });

    return data;
  }

  async acceptReferral(referralId) {
    await this.post('/referral/accept', { referralId });
  }

  async declineReferral(referralId) {
    await this.post('/referral/decline', { referralId });
  }

  async confirmResultsAdded(referralId) {
    await this.post(`/referral/confirm-results-added?referralId=${referralId}`);
  }

  async uploadResult(referralId, files) {
    await this.post(
      `/referral/laboratory-service/upload-result?referralId=${referralId}`,
      files,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    );
  }

  async deleteFile(fileId) {
    await this.delete(
      `/referral/laboratory-service/delete-result?fileId=${fileId}`
    );
  }

  async downloadFile(fileId) {
    const { data } = await this.get(
      `/referral/laboratory-service/download-result?fileId=${fileId}`,
      {
        responseType: 'arraybuffer'
      }
    );

    return data;
  }
}

export default ReferralAnalysisApi;
