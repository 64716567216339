import NotificationsApi from './notifications.api';
import { NOTIFICATION_LINK_SUBJECT } from '@/services/notifications/notifications.const.js';

class NotificationsService {
  constructor(client, hub, $config) {
    this.api = new NotificationsApi(client, $config);
    this.hub = hub;
  }

  async getNotifications() {
    const { data } = await this.api.list();
    return data;
  }

  async unreadNotifications(ids) {
    const { data } = await this.api.unread(ids);
    return data;
  }

  async clearNotifications(ids) {
    const { data } = await this.api.delete(ids);
    return data;
  }

  async readNotification(ids) {
    const { data } = await this.api.read(ids);
    return data;
  }

  getLink(notification) {
    if (!notification) return;

    const linkID = notification.notificationLink.subjectId;

    if (notification.notificationLink.subjectData) {
      switch (notification.notificationLink.subjectData.referralType) {
        case NOTIFICATION_LINK_SUBJECT.TYPELESS:
          return `/medicine-directions/${linkID}`;
        case NOTIFICATION_LINK_SUBJECT.MEDICINE:
          return `/referral/analysis/${linkID}`;
        case NOTIFICATION_LINK_SUBJECT.LABORATORY_SERVICE:
          return `/referral/consultations/${linkID}`;
        case NOTIFICATION_LINK_SUBJECT.TELEMEDCONSULTATION:
          return `/referral/analysis/${linkID}`;
        default:
          return `/`;
      }
    }
  }
}

export default NotificationsService;
