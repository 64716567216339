import { Api } from '~/api';

class MomsSchoolApi extends Api {
  constructor(client, $config) {
    const baseUrl = $config.APP_NEW_API;
    super(client, baseUrl);
  }

  list(params) {
    return this.get('/v3/moms-school-event/list', {
      params,
      paramsSerializer: (params) => params
    });
  }

  getEvent(eventId) {
    return this.get(`/v3/moms-school-event/get?eventId=${eventId}`);
  }

  checkAccess() {
    return this.get('/v3/moms-school-event/check-access');
  }

  register(eventId) {
    return this.post(`/v3/moms-school-event/register?eventId=${eventId}`);
  }

  deregister(eventId) {
    return this.post(`/v3/moms-school-event/deregister?eventId=${eventId}`);
  }
}

export default MomsSchoolApi;
