import { XVCProjectEnum } from '../../consts';
import { Api } from '~/api';

class PatientApi extends Api {
  constructor(client, $config) {
    const baseUrl = `${$config.APP_NEW_API}/`;
    super(client, baseUrl);

    this.baseURL = baseUrl;
  }

  getProfile() {
    return this.get('patient/current', {
      params: {
        projectType: XVCProjectEnum.DOCTIS
      }
    });
  }

  updateProfile(payload) {
    return this.put('patient/fill', payload);
  }

  createFamilyProfile(payload) {
    return this.post('patient/family-profile', payload);
  }

  sendProfileUpdateReport() {
    return this.post('v3/patient/send-profile-update-report');
  }

  getFamilyProfiles() {
    return this.get('patient/family-profiles');
  }

  getSetting() {
    return this.get('settings/patient');
  }

  async getNotSignAgreements(params) {
    const { data } = await this.get('agreement/getNotSignAgreements', {
      params
    });

    return data;
  }

  async getAgreementsForConsultation(doctorId) {
    try {
      const { data } = await this.get(
        `consultation/getAgreementsForConsultation?doctorId=${doctorId}`
      );

      return data;
    } catch (e) {
      return [];
    }
  }
}

export default PatientApi;
