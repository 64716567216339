export default class ClinicService {
  constructor(client) {
    this.client = client;
  }

  /**
   * @param {Object} params = {}
   * @param {string} params.search
   * @param {string} params.specialization_id
   */
  get(params = {}) {
    return this.client.post('/clinic/info', { ...params });
  }
}
