class MailruMetrikaService {
  constructor(id) {
    this.MR_ID = id;
    const _tmr = window._tmr || (window._tmr = []);
    _tmr.push({ id, type: 'pageView', start: new Date().getTime() });
    (function(d, w, id) {
      if (d.getElementById(id)) return;
      const ts = d.createElement('script');
      ts.type = 'text/javascript';
      ts.async = true;
      ts.id = id;
      ts.src = 'https://top-fwz1.mail.ru/js/code.js';
      const f = function() {
        const s = d.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(ts, s);
      };
      if (w.opera === '[object Opera]') {
        d.addEventListener('DOMContentLoaded', f, false);
      } else {
        f();
      }
    })(document, window, 'tmr-code');
  }

  sendGoal(target, id) {
    if (!id) {
      id = this.MR_ID;
    }
    if (window && window._tmr) {
      window._tmr.push({
        type: 'reachGoal',
        id,
        goal: target
      });
    }
  }
}

export default MailruMetrikaService;
