<template>
  <div class="flex justify-center py-1 px-4">
    <v-chat-message type="Info" :text="message.text" />
  </div>
</template>

<script>
import { VChatMessage } from '@doctis.front/doctis.designsystem';
export default {
  name: 'SupportChatMessageSystem',
  components: { VChatMessage },
  props: {
    message: {
      type: Object,
      required: true
    }
  }
};
</script>
