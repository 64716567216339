import { v4 as uuidv4 } from 'uuid';

export default class Logger {
  constructor(client, $config, $auth, $ua) {
    this.baseURL = $config.APP_LOGGER_URL;
    this.client = client;
    this.$config = $config;
    this.$auth = $auth;
    this.$ua = $ua;

    this.logs = [];
    this.logsTimer = setInterval(this.sendLogs.bind(this), 1000);
  }

  log(args) {
    const log = {
      version: '1.1',
      host: 'web',
      timestamp: Date.now() / 1000,
      message: args
        .map((arg) => (typeof arg === 'object' ? stringify(arg, 4) : arg))
        .join('\r\n'),
      level: 6,
      'vc-platform': this.$config.APP_VC_PLATFORM,
      'vc-project': this.$config.APP_VC_PROJECT,
      'vc-app-version': this.$config.APP_VC_VERSION,
      environment: this.$config.APP_ENVIRONMENT,
      'user-id': this.$auth.user?.id ?? null,
      'profile-id': this.$auth.user?.id ?? null,
      guid: uuidv4(),
      bearer: this.$auth.strategy.token.get() ?? null,
      'user-agent': this.$ua.deviceType()
      // 'network-type': 'wifi',
    };
    this.logs.push(log);
  }

  sendLogs() {
    if (this.logs.length) {
      this.client.post(
        this.baseURL,
        this.logs.map((log) => stringify(log)).join('\r\n')
      );
      this.logs = [];
    }
  }
}

function stringify(obj, spaces = 0) {
  let cache = [];
  const str = JSON.stringify(
    obj,
    function(key, value) {
      if (typeof value === 'object' && value !== null) {
        if (cache.includes(value)) {
          // Circular reference found, discard key
          return;
        }
        // Store value in our collection
        cache.push(value);
      }
      return value;
    },
    spaces
  );
  cache = null; // reset the cache
  return str;
}
