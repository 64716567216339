import qs from 'query-string';
import { Api } from '~/api';

class DirectionsApi extends Api {
  constructor(client, $config) {
    const baseUrl = $config.APP_NEW_API + '/v3';
    super(client, baseUrl);
  }

  referralMedicineInfo(params) {
    return this.get('/referral/medicine/info', {
      params,
      paramsSerializer: (params) =>
        qs.stringify(params, {
          skipNull: true
        })
    });
  }

  referralAccept(params) {
    return this.post('/referral/accept', params);
  }

  referralDecline(params) {
    return this.post('/referral/decline', params);
  }
}

export default DirectionsApi;
