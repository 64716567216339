import ServiceApi from '@/api/serviceApi';
import CoreApi from '@/api/coreApi';
import FileApi from '@/api/fileApi';
import LandingApi from '@/api/landingApi';

export default function({ $axiosClient, $config, $store, $auth }, inject) {
  const client = $axiosClient.instance;

  const serviceApi = new ServiceApi(client, $config);
  const fileApi = new FileApi(client, $config);
  const coreApi = new CoreApi(client, $config, $store);
  const landingApi = new LandingApi(client, $config);

  inject('serviceApi', serviceApi);
  inject('fileApi', fileApi);
  inject('coreApi', coreApi);
  inject('landingApi', landingApi);
}
