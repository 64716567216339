import axios from 'axios';
import Logger from '@/services/core/logger';

export default function({ $axios, $config, $auth, $ua }) {
  if ($config.APP_LOGS_ENABLED) {
    const client = axios.create({
      headers: { 'Content-Type': 'text/json' }
    });

    const logger = new Logger(client, $config, $auth, $ua);

    if (console.log) {
      const cl = console.log;
      console.log = function(...args) {
        logger.log(args);
        cl.apply(this, arguments);
      };
    }
  }
}
