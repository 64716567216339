import SeoApi from './seo.api';

class SeoService {
  constructor(client, hub, $config) {
    this.api = new SeoApi(client, $config);
    this.hub = hub;
  }

  async specialization(specializationId) {
    const { data } = await this.api.getSpecialization(specializationId);
    return data;
  }
}

export default SeoService;
