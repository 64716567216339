import { Api } from '~/api';

class FilesApi extends Api {
  constructor(client, $config) {
    const baseUrl = $config.APP_FILE_API;
    super(client, baseUrl);
    this.baseURL = baseUrl;
  }

  async getFilesByFolderName(folder) {
    const { data } = await this.get(`/v1/file/folder/${folder}/files`);
    return data;
  }

  async getFileByFilename(filename, mimeType) {
    const { data } = await this.get(`/v1/file/${filename}`, {
      responseType: 'arraybuffer'
    });

    return data;
  }

  async saveFile(file, category, mimeType, fileTagId) {
    const { data } = await this.post(`/v1/file/save/${category}`, file, {
      params: {
        mimeType,
        fileTagId
      }
    });
    return data;
  }
}

export default FilesApi;
