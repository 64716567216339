<template>
  <div
    class="flex items-end pl-4 pr-2.5 my-2 w-full"
    :class="!message.incoming ? 'justify-end pl-4 pr-2.5' : 'pr-4 pl-2.5'"
  >
    <v-chat-message
      :type="message.messageType"
      :text="message.text"
      :is-incoming="message.incoming"
      is-short
      :time-stamp="message.time"
      :message-options="messageOptions"
      :is-error="message.isError"
      :is-sending="message.isSending"
      :was-read="message.wasRead"
      has-avatar
      :image-url="
        message.avatar || require('@/assets/images/ui/chat/support.svg')
      "
      @onMenuSelect="onSelectOption"
    />
  </div>
</template>

<script>
import { VChatMessage } from '@doctis.front/doctis.designsystem';
import linkifyStr from 'linkifyjs/string';
import {
  FILTER_MESSAGE_OPTIONS,
  MESSAGE_OPTIONS
} from '@/services/files/files.const';
import { TOASTS } from '@/services/core/ui/toasts.const';
import { filterOptions } from '@/services/files/files.helpers';

export default {
  name: 'SupportChatMessageText',
  components: {
    VChatMessage
  },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  computed: {
    name() {
      return this.message?.name;
    },
    text() {
      return linkifyStr(this.message.text, {
        defaultProtocol: 'https',
        attributes: {
          class: 'text-v-ui-text-service-info-blue',
          target: '_blank'
        }
      }).replaceAll(/\n/g, '<br>');
    },
    messageOptions() {
      return filterOptions({
        message: this.message,
        options: MESSAGE_OPTIONS,
        filterOptions: FILTER_MESSAGE_OPTIONS,
        isProgressVisible: this.isProgressVisible,
        isPreview: this.isPreview
      });
    }
  },
  methods: {
    onSelectOption(select) {
      if (select.key === 'Copy') {
        navigator.clipboard.writeText(this.text);
        this.$uiService.showToast(TOASTS.MESSAGE_COPIED);
      } else {
        this.$emit('setMessage', {
          selected: select.key,
          messageId: this.message.id
        });
      }
    },
    filterOptions(options) {
      if (this.message.isSending)
        return options.filter((item) =>
          Object.values(
            this.$t('consts.FILTER_MESSAGE_OPTIONS.isSending.keys')
          ).includes(item.key)
        );
      else if (this.message.isError)
        return options.filter((item) =>
          Object.values(
            this.$t('consts.FILTER_MESSAGE_OPTIONS.isError.keys')
          ).includes(item.key)
        );
      else
        return options.filter((item) =>
          Object.values(
            this.$t('consts.FILTER_MESSAGE_OPTIONS.default.keys')
          ).includes(item.key)
        );
    }
  }
};
</script>
