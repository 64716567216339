export const state = () => ({
  appointment: null,
  specializations: [],
  clinics: [],
  slot: null,
  date: null
});

export const mutations = {
  setSpecializations(state, specializations) {
    state.specializations = specializations;
  },
  setClinics(state, clinics) {
    state.clinics = clinics;
  },
  setAppointment(state, appointment) {
    state.appointment = appointment;
  },
  setSlot(state, slot) {
    state.slot = slot;
  },
  setDate(state, date) {
    state.date = date;
  }
};

export const actions = {
  async getSpecializations({ state, commit }) {
    try {
      if (state.specializations.length) {
        return;
      }
      const { data } = await this.$coreApi.getSpecializations();
      const specializations = data.specializations;
      if (specializations) {
        commit('setSpecializations', specializations);
      }
    } catch (error) {
      console.log('Ошибка при получении специальностей', error);
    }
  },
  async getClinics({ state, commit }) {
    try {
      if (state.clinics.length) {
        return;
      }
      const clinics = await this.$coreApi.getClinicListByDoctors();
      if (clinics) {
        commit('setClinics', clinics);
      }
    } catch (error) {
      console.log('Ошибка при получении клиник', error);
    }
  },
  async setAppointmentAfterAuth({ state, commit }, routeIdDoctor) {
    const doctorId = this.$cookies.get('doctorId');

    if (doctorId !== undefined || routeIdDoctor !== undefined) {
      const id = doctorId || routeIdDoctor;
      const doctor = await this.$coreApi.getDoctor(id);
      commit('setAppointment', doctor);
    }
  }
};

export const getters = {};

export default {
  state,
  getters,
  actions,
  mutations
};
