import { CURRECIES } from './intl.consts';

class IntlService {
  constructor($config) {
    this.locale = $config.APP_LOCALE || 'ru';
  }

  getPrice(price, minimumFractionDigits = 0) {
    const currency = CURRECIES[this.locale] || 'ru';

    console.log('price', price);
    return new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency,
      minimumFractionDigits
    }).format(price);
  }
}

export default IntlService;
