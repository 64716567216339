import * as AxiosLogger from 'axios-logger';
import axiosBetterStacktrace from 'axios-better-stacktrace';
import { arrayBufferToObject } from '~/services/files/files.helpers';
import { MODALS } from '@/services/core/ui/const';

export default class AxiosClient {
  constructor($axios, app, $config, $sentry, $uiService) {
    this.instance = $axios;
    this.logger = AxiosLogger;

    this.instance.setHeader(
      'Browser',
      `${app.$ua.browser()}/${app.$ua.browserVersion()}`
    );
    this.instance.setHeader('OS', `${app.$ua.os()}/${app.$ua.osVersion()}`);

    axiosBetterStacktrace(this.instance);

    this.instance.onRequest((request) => {
      request.errorContext = new Error('Thrown at:');
      if ($config.APP_AXIOS_DEBUG) {
        this.logger.requestLogger(request, {
          dateFormat: 'dd-mm-yy HH:MM:ss',
          params: true,
          headers: true
        });
      }

      return request;
    });

    this.instance.onRequestError((error) => {
      this.logger.requestLogger(error, {
        params: true,
        dateFormat: 'dd-mm-yy HH:MM:ss'
      });

      $sentry.captureException(error);

      throw error;
    });

    this.instance.onResponseError(async (error) => {
      if (this.instance.isCancel(error)) {
        return;
      }

      const originalStackTrace = error.config?.errorContext?.stack;
      if (originalStackTrace) {
        error.stack = `${error.stack}\n${originalStackTrace}`;
      }

      let responseData = error.response?.data;

      if (error.response.data instanceof ArrayBuffer && responseData) {
        responseData = arrayBufferToObject(responseData);
      }

      if (responseData && responseData.code === 9999) {
        if (process.browser) {
          $uiService.showModal(MODALS.REQUEST_ERROR_DIALOG, {
            props: {
              errorId: responseData.data?.errorId
            }
          });
        } else {
          await app.store.dispatch('addAxiosError', responseData.data?.errorId);
        }
      }

      this.logger
        .errorLogger(error, {
          params: true,
          dateFormat: 'dd-mm-yy HH:MM:ss'
        })
        .catch(() => {});
      if (error?.response?.status === 401 && app.$auth.loggedIn) {
        return app.$authService.logout();
      }

      $sentry.captureException(error);

      throw error;
    });
  }
}
