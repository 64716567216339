import { Api } from '~/api';

class NotificationsApi extends Api {
  constructor(client, $config) {
    const baseUrl = $config.APP_NEW_API;
    super(client, baseUrl);
  }

  list(params) {
    return this.get('/notification/list', {
      params,
      paramsSerializer: (params) => params
    });
  }

  read(notificationIds) {
    return this.post('/notification/read', { notificationIds });
  }

  unread(notificationIds) {
    return this.post('/notification/unread', { notificationIds });
  }

  delete(notificationIds) {
    return this.post('/notification/delete', { notificationIds });
  }
}

export default NotificationsApi;
