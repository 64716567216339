import { MODALS } from '@/services/core/ui/const';

const DEVICE_TYPES = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop'
};

export const state = () => ({
  isMobile: undefined,
  isMobileSM: undefined,

  isMobileDevice: null,
  deviceType: DEVICE_TYPES.MOBILE,

  ssrAxiosErrors: [],

  isNewMessageSupportChat: false,

  abFlags: null
});

export const actions = {
  nuxtClientInit({ state, dispatch }) {
    if (state.ssrAxiosErrors.length) {
      state.ssrAxiosErrors.forEach((error) => {
        this.$uiService.showModal(MODALS.REQUEST_ERROR_DIALOG, {
          props: { errorId: error }
        });
      });
      dispatch('resetAxiosErrors');
    }
  },
  async nuxtServerInit({ rootState, dispatch, commit }) {
    commit('setIsMobileDevice', this.$device.isMobileOrTablet);

    if (rootState.auth.loggedIn) {
      await dispatch('userInit');
    }
  },
  async initAfterAuth({ dispatch }) {
    this.$amplitude.logEvent('auth_finished', { result: true });
    if (process.client) {
      await dispatch('userInit');
      await this.$hubClient.init();
      await this.$dispatcherClient.init();
      await dispatch('modules/moms-school/checkAccess');
      await dispatch('modules/notification/fetchNotifications');
    }
  },
  async userInit({ dispatch }) {
    try {
      await dispatch('modules/patient/fetchProfile');
      await dispatch('modules/patient/fetchFamilyProfiles');
      await dispatch('modules/profile/getProfile');
      await dispatch('modules/profile/getUserProfiles');
      await dispatch('modules/profile/getSetting');
    } catch (error) {
      await this.$authService.logout();
      console.log(error);
    }

    // await dispatch('modules/appointment/setAppointmentAfterAuth');
  },
  addAxiosError({ commit }, error) {
    commit('addAxiosError', error);
  },
  resetAxiosErrors({ commit }) {
    commit('resetAxiosErrors');
  },
  setABFlags({ commit }, flags) {
    commit('setABFlags', flags);
  }
};

export const mutations = {
  setIsMobile(state) {
    const vh = window.innerHeight * 0.01;
    const width = window.innerWidth;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    state.isMobile = width < 992;
    state.isMobileSM = width < 576;

    state.deviceType = state.isMobile
      ? DEVICE_TYPES.MOBILE
      : DEVICE_TYPES.DESKTOP;

    state.isMobileDevice = state.isMobile;
  },
  addAxiosError(state, error) {
    state.ssrAxiosErrors.push(error);
  },
  resetAxiosErrors(state) {
    state.ssrAxiosErrors = [];
  },
  setIsNewMessageSupportChat(state, value) {
    state.isNewMessageSupportChat = value;
  },
  setIsMobileDevice(state, isMobile) {
    state.deviceType = isMobile ? DEVICE_TYPES.MOBILE : DEVICE_TYPES.DESKTOP;

    state.isMobileDevice = isMobile;
  },
  setABFlags(state, flags) {
    // console.log(flags);
    state.abFlags = flags;
  }
};

export const strict = false;
