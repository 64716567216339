import { v4 as uuidv4 } from 'uuid';

export default class UIService {
  constructor($store, i18n) {
    this.$store = $store;
    this.$t = i18n.t.bind(i18n);
  }

  showModal(modal, options = {}) {
    const { modals } = this.$store.state.modules.ui;
    console.log('modals', modals);

    const isAlreadyExist = modals.findIndex((m) => m.id === modal.id) !== -1;

    if (isAlreadyExist) {
      return;
    }

    const modalObject = {
      id: modal.id,
      component: modal.component,
      props: options.props ?? {},
      actions: options.actions ?? {}
    };

    this.$store.dispatch('modules/ui/showModal', modalObject);
  }

  closeModalById(modalId) {
    this.$store.dispatch('modules/ui/closeModalById', modalId);
  }

  clearModals() {
    this.$store.dispatch('modules/ui/clearModals');
  }

  clearToasts() {
    this.$store.dispatch('modules/ui/clearToasts');
  }

  showToast(toastName) {
    const toast = {
      id: uuidv4(),
      label: this.$t(`ui.toasts.${toastName}`)
    };

    this.$store.dispatch('modules/ui/showToast', toast);
  }

  showToastWithMessage(message) {
    const toast = {
      id: uuidv4(),
      label: message
    };

    this.$store.dispatch('modules/ui/showToast', toast);
  }

  hideToastById(toastId) {
    this.$store.dispatch('modules/ui/hideToastById', toastId);
  }
}
