<template>
  <div
    class="min-h-screen bg-v-ui-root-monochrome-5"
    data-theme="default"
    :class="{ 'deactive-menu': $route.name === 'events-id' }"
  >
    <vc-application-notification />
    <u-navbar :logged-in="$auth.loggedIn" :moms-school="access" />

    <nuxt class="container mt-6 pb-4 !max-w-[690px] px-4" />

    <client-only>
      <SystemModals />
    </client-only>

    <common-modal-container />
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import VcApplicationNotification from '../components/domains/application/VcApplicationNotification.vue';
import CommonModalContainer from '../components/common/CommonModalContainer.vue';

export default {
  name: 'Default',
  auth: false,
  components: {
    CommonModalContainer,
    VcApplicationNotification,
    UNavbar: () => import('@/components/ui/organisms/navbar/UNavbar'),
    SystemModals: () => import('@/components/modal/SystemModals')
  },
  head() {
    return {
      title: this.$t('domains.domains.layouts.default.title')
    };
  },
  mounted() {
    this.setIsMobile();
    this.setAppointmentAfterAuth(this.$route.query?.doctorAppointment);
    if (this.$auth.loggedIn) {
      this.$statusService.subscribe();
      window.addEventListener('resize', this.setIsMobile);
      this.checkAccess();
    }
  },
  methods: {
    ...mapMutations(['setIsMobile']),
    ...mapActions('modules/appointment', ['setAppointmentAfterAuth']),
    ...mapActions('modules/moms-school', ['checkAccess'])
  },
  computed: {
    ...mapState('modules/moms-school', ['access'])
  }
};
</script>

<style lang="scss">
.default {
  //display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;

  & > .content-main {
    background: var(--root-base3);
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;

    & > .page {
      height: 100%;
      width: 690px;
      padding: 0 0 200px;
      margin: 24px auto;
    }
  }

  // for mobile
  @media (max-width: 710px) {
    padding-bottom: var(--heightBottomMenu);

    & > .content-main {
      padding-top: 0;

      & > .page {
        display: block;
        width: 100%;
        padding: 15px 15px 200px;

        &.preloader {
          display: flex;
        }
      }
    }

    &.deactive-menu {
      padding-bottom: 0;

      .doc-menu {
        display: none;
      }

      .content-main {
        & > .consultation {
          .chat {
            padding-bottom: 0;
          }
        }

        & > .page {
          padding-bottom: 180px !important;
        }
      }
    }
  }
}
</style>
