export default function({ $axiosClient, $config }, inject) {
  return import('@/services/promotion/promotion.service').then(
    (PromotionService) => {
      // eslint-disable-next-line
      const promotionService = new PromotionService.default(
        $axiosClient.instance,
        $config
      );
      inject('promotionService', promotionService);
    }
  );
}
