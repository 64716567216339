import PaymentService from '@/services/payment/payment.service';

export default function(
  { $axios, $auth, $axiosClient, $hubClient, $config },
  inject
) {
  const client = $axiosClient.instance;

  const paymentService = new PaymentService(client, $hubClient, $config);

  inject('paymentService', paymentService);
}
