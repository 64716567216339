import { formatDuration } from '@evd3v/date-fns';
import { downloadFile } from '@/services/files/files.helpers';
import { getResultFiles } from '@/services/analysis/analysis.helpers';
import AnalysisApi from '@/services/analysis/analysis.api';

class AnalysisService {
  constructor(client, $config, i18n) {
    this.api = new AnalysisApi(client, $config);
    this.$t = i18n.t.bind(i18n);
  }

  async getAnalysis(id) {
    const { data } = await this.api.getAnalysis(id);

    return {
      ...data,
      patient: this.getPatient(data.patient)
    };
  }

  async cancelPurchaseAnalysis(id) {
    const { data } = await this.api.cancelPurchaseAnalysis(id);
    return data;
  }

  async confirmResultAnalysis(id) {
    const { data } = await this.api.confirmResultAnalysis(id);
    return data;
  }

  async uploadResultAnalysis(files, id) {
    const formData = new FormData();
    files.forEach(({ file }, index) => {
      formData.append(`files`, file);
    });
    const { data } = await this.api.uploadResultAnalysis(formData, id);
    return data;
  }

  async deleteResultAnalysis(id) {
    await this.api.deleteResultAnalysis(id);
  }

  async downloadFile({ id, name }) {
    const file = await this.api.downloadFile(id);
    downloadFile(new Blob([file]), name);
  }

  downloadAllAnalysis(files) {
    files = getResultFiles(files);
    console.log('files', files);
    for (const file of files) {
      this.downloadFile(file);
    }
  }

  getPatient(patient) {
    const { lastName, firstName, middleName, gender, age } = patient;
    return {
      ...patient,
      fullName: `${lastName} ${firstName} ${middleName}`,
      male:
        gender === 'Male'
          ? this.$t('common.general-words.male')
          : this.$t('common.general-words.female'),
      age: formatDuration({ years: age })
    };
  }

  async createPayture(params) {
    return await this.api.createPayture(params);
  }
}

export default AnalysisService;
