import { Api } from '~/api';

class DoctorApi extends Api {
  constructor(client, $config) {
    const baseUrl = $config.APP_NEW_API + '/doctor';
    super(client, baseUrl);

    this.baseURL = baseUrl;
  }

  /**
   * @param {number} id
   * @returns {Promise<DoctorProfileResponse>}
   */
  async getProfile(id) {
    const { data } = await this.get(`/profile/${id}`);
    return data;
  }

  addToFavorite(doctorId) {
    return this.post('/favorite', { doctorId });
  }

  removeFromFavorite(doctorId) {
    return this.delete('/favorite', { data: { doctorId } });
  }

  /**
   * @param {number} doctorId
   * @param {string} orderBy
   * @returns {*}
   */
  async listReviews(doctorId, orderBy) {
    const { data } = await this.get(`/${doctorId}/reviews`, {
      params: { orderBy }
    });
    return data;
  }

  /**
   * @param {string} doctor_id
   * @param {string} type
   */
  async getRegaliaFiles(doctor_id, type) {
    const { data } = await this.get(`/${doctor_id}/regalia-files`, {
      params: { doctor_id, type },
      responseType: 'arraybuffer'
    });
    return data;
  }

  /**
   * @param {string} key
   * @returns {Promise<*>}
   */
  async getFile(key) {
    const { data } = await this.get('/download-file', {
      params: { key },
      responseType: 'arraybuffer'
    });
    return data;
  }
}

export default DoctorApi;
