export const state = () => ({
  isLoading: false,
  isError: false,
  events: [],
  event: {},
  access: false
});

export const mutations = {
  SET_EVENTS(state, events) {
    state.events = events;
  },
  SET_ISLOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_ISERROR(state, isError) {
    state.isError = isError;
  },
  SET_EVENT(state, event) {
    state.event = event;
  },
  SET_ACCESS(state, access) {
    state.access = access;
  }
};

export const actions = {
  async fetchEvents({ commit }) {
    try {
      commit('SET_ISERROR', false);
      commit('SET_ISLOADING', true);
      const events = await this.$momsSchoolService.getEvents();
      commit('SET_EVENTS', events.data);
    } catch (error) {
      console.log('error fetchEvents: ', error);
      commit('SET_ISERROR', true);
    } finally {
      commit('SET_ISLOADING', false);
    }
  },
  async fetchEvent({ commit }, eventId) {
    try {
      commit('SET_ISERROR', false);
      commit('SET_ISLOADING', true);
      const event = await this.$momsSchoolService.getEvent(eventId);
      commit('SET_EVENT', event.data);
    } catch (error) {
      console.log('error fetchEvent: ', error);
      commit('SET_ISERROR', true);
    } finally {
      commit('SET_ISLOADING', false);
    }
  },
  async checkAccess({ commit }) {
    const access = await this.$momsSchoolService.checkAccess();
    commit('SET_ACCESS', access.data);
  },
  async register({ commit }, eventId) {
    await this.$momsSchoolService.register(eventId);
  },
  async deregister({ commit }, eventId) {
    await this.$momsSchoolService.deregister(eventId);
  }
};
