export const STATUS = {
  NEW: 'New',
  ACCEPTED: 'Accepted',
  PROVIDED: 'Provided',
  DECLINED: 'Declined',
  ALMOST_OVER: 'AlmostOver' // Для прогресс бара
};

export const TYPE_PROGRESS = {
  OPEN: 'default',
  ALMOST_OVER: 'Accent',
  PROVIDED: 'Provided'
};

export const INFO_MESSAGE = {
  takeTest: {
    type: 'main',
    title: 'Сдайте анализы',
    text: 'Мы подгрузим сюда результаты, как только они придут из лаборатории'
  },
  // waitResults: {
  //   type: 'info',
  //   title: 'Подождите все результаты',
  //   text: 'Мы подгружаем сюда результаты по мере поступления'
  // },
  passedFullTests: {
    type: 'main',
    title: 'Вы сдали анализы',
    text:
      'Они будут храниться в вашей медкарте. Для получения новых рекомендаций вы можете записаться к врачу заново'
  },
  // passedPartTests: {
  //   type: 'info',
  //   title: 'Вы сдали часть анализов',
  //   text:
  //     'Они будут храниться в вашей медкарте. Для получения новых рекомендаций вы можете записаться к врачу заново'
  // },
  downloadResults: {
    type: 'main',
    title: 'Подгрузите результаты',
    text:
      'Они будут храниться в вашей медкарте и видны врачу при повторной консультации'
  },
  recommendationCancel: {
    type: 'main',
    title: 'Отклоненная рекомендация',
    text:
      'Ранее вы отклонили эту рекомендацию, то есть отказались от назначенного лечения'
  }
  // recommendationClosed: {
  //   type: 'info',
  //   title: 'Рекомендация закрыта',
  //   text:
  //     'Срок действия рекомендации на анализы прошёл. Для получения новых рекомендаций вы можете записаться к врачу заново'
  // }
};
