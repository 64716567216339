export const DEFAULT_FILTERS = {
  fullName: '',
  specializationIds: [],
  clinicIds: [],
  clinicNetworkIds: [],
  categories: [],
  scienceDegrees: [],
  ranks: [],
  minimumYearsOfExperience: null,
  appointmentDateFrom: null,
  appointmentDateTo: null,
  appointmentTypes: [],
  communicationMethods: [],
  showUnscheduledDoctors: false,
  minPrice: null,
  maxPrice: null,
  sortBy: 'NearestAppointment',
  offset: 0,
  limit: 30,
  referralRecommendationId: null
};

export const UNSCHEDULED_DOCTORS = [
  {
    id: true,
    label: 'domains.doctors.filters.view.list.unscheduled',
    value: false
  }
];

export const EXPERIENCE = [
  { id: null, label: 'domains.doctors.filters.experience.list.any' },
  { id: 5, label: 'domains.doctors.filters.experience.list.five' },
  { id: 10, label: 'domains.doctors.filters.experience.list.ten' }
];

export const DATE_ADMISSION = [
  { id: null, title: 'domains.doctors.filters.date-appointment.list.any' },
  { id: 'TODAY', title: 'domains.doctors.filters.date-appointment.list.today' },
  { id: 'FIVE', title: 'domains.doctors.filters.date-appointment.list.five' }
];

export const LIST_SORT_DOCTOR = [
  {
    id: 'FavouritesFirst',
    title: 'domains.doctors.sort.favourites-first',
    isAuth: true
  },
  {
    id: 'PriceAscending',
    title: 'domains.doctors.sort.price-ascending',
    isAuth: false
  },
  {
    id: 'PriceDescending',
    title: 'domains.doctors.sort.price-descending',
    isAuth: false
  },
  {
    id: 'PopularFirst',
    title: 'domains.doctors.sort.popular-first',
    isAuth: false
  },
  {
    id: 'NearestAppointment',
    title: 'domains.doctors.sort.nearest-appointment',
    isAuth: false
  }
];
