import { createBlobFromArrayBuffer } from '@/services/files/files.helpers';
import { Api } from '@/api/index';

export default class FileApi extends Api {
  constructor($axios, $config) {
    const baseUrl = $config.APP_FILE_API;
    super($axios, baseUrl);
  }

  /**
   * @param folderName
   * @return {Promise<Array.<ConsultationFile>>}
   */
  async getFilesByFolderName(folderName) {
    const { data } = await this.get(`/v1/file/folder/${folderName}/files`);
    return data;
  }

  // TODO после уточнения у Жени по поводу api/content
  // async getFilesUserAgreements() {
  //   const { data } = await this.get('content/user-agreements.pdf');
  //   return data;
  // }
  //
  // async getFilesPersonalDataConsent(folderName) {
  //   const { data } = await this.get('content/personal-data-consent.pdf');
  //   return data;
  // }

  /**
   * @param {string} filename
   * @param {string} mimeType
   * @return {Promise<Blob>}
   */
  async getFileByFilename(filename, mimeType) {
    const { data } = await this.get(`/v1/file/${filename}`, {
      responseType: 'arraybuffer'
    });
    return createBlobFromArrayBuffer(data, mimeType);
  }

  /**
   * @param {FormData} file
   * @param {string} category
   * @param {string} mimeType
   * @param {number} fileTagId
   * @return {Promise<string>}
   */
  async saveFile(file, category, mimeType, fileTagId) {
    const { data } = await this.post(`/v1/file/save/${category}`, file, {
      params: {
        mimeType,
        fileTagId
      }
    });
    return data;
  }
}
