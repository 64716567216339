<template>
  <nuxt-link v-if="inner" :to="link" @click.native="onClick">
    <slot></slot>
  </nuxt-link>
  <a v-else :href="outerLink" @click="onClick">
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'ULink',
  props: {
    inner: {
      type: Boolean,
      default: false
    },
    link: {
      type: [String, Object],
      required: true
    }
  },
  computed: {
    outerLink() {
      if (typeof this.link === 'string') {
        return this.link;
      } else {
        return this.link?.path;
      }
    }
  },
  methods: {
    onClick() {
      this.$emit('click');
    }
  }
};
</script>
