import DoctorsApi from './doctors.api';
import { formatedListsFromFilters, addTypeInList } from './doctors.helpers';
import {
  DEFAULT_FILTERS,
  UNSCHEDULED_DOCTORS
} from '@/services/doctors/doctors.const';

class DoctorsService {
  constructor(client, $config, i18n) {
    this.api = new DoctorsApi(client, $config);
    this.$t = i18n.t.bind(i18n);

    this.filters = { ...DEFAULT_FILTERS };
    this.filtersParams = {
      specializations: [],
      clinics: [],
      regalies: [],
      appointmentTypes: [],
      communicationMethods: []
    };

    this.price = {
      selectedMin: 0,
      selectedMax: 0,
      min: 0,
      max: 0
    };

    /** Параметры для фильтров */
    this.showUnscheduledDoctors = UNSCHEDULED_DOCTORS;

    /** Выбранная специализация */
    this.specialization = '';

    /** Первичная загрузка */
    this.isFirstLoading = true;

    /** Запрещаем отправку запроса на получени списка врачей */
    this.isStopGetDoctors = true;

    /** Подргрузка врачей */
    this.doctors = [];
    this.isGetNewDoctors = false;

    /** LOADER - */
    this.doctorsInfo = {
      isLoadingFilter: true,
      countDoctors: 0
    };
  }

  async getDoctors() {
    this.doctorsInfo.isLoadingFilter = true;
    if (!this.isGetNewDoctors) this.filters.offset = 0;

    const data = await this.api.getDoctors(this.filters);
    if (!data) return null;

    const { doctors, filters, count } = data;

    let currentDoctors = doctors;
    this.doctorsInfo.countDoctors = count;

    /** Обновляем созависиммые фильны */
    this.codependentFilters(filters);

    /** Подгрузка врачей */
    if (this.isGetNewDoctors) {
      currentDoctors = [...this.doctors, ...doctors];
      this.isGetNewDoctors = false;
    }

    this.doctors = currentDoctors;

    this.doctorsInfo.isLoadingFilter = false;

    return this.formirationListDoctors(currentDoctors);
  }

  /** Созависиммые фильтры */
  codependentFilters({
    clinics,
    specializations,
    categories,
    scienceDegrees,
    ranks,
    appointmentTypes,
    communicationMethods,
    showUnscheduledDoctors,
    selectedMaxPrice,
    maxPrice,
    selectedMinPrice,
    minPrice
  }) {
    this.isStopGetDoctors = true;

    this.filtersParams.clinics = formatedListsFromFilters(clinics);
    this.filtersParams.specializations = formatedListsFromFilters(
      specializations
    );

    const regalies = [
      ...addTypeInList(categories, 'categories'),
      ...addTypeInList(scienceDegrees, 'scienceDegrees'),
      ...addTypeInList(ranks, 'ranks')
    ];
    this.filtersParams.regalies = this.formatedListsFromFiltersWithTranslate(
      regalies,
      'domains.doctors.filters.regalia.list'
    );

    this.filtersParams.appointmentTypes = this.formatedListsFromFiltersWithTranslate(
      appointmentTypes,
      'domains.doctors.filters.accepts.list'
    );

    this.filtersParams.communicationMethods = this.formatedListsFromFiltersWithTranslate(
      communicationMethods,
      'domains.doctors.filters.communication.list'
    );

    this.showUnscheduledDoctors[0].value = showUnscheduledDoctors;

    /** Цена */
    this.price.min = minPrice;
    this.price.max = maxPrice;

    this.price.selectedMin = selectedMinPrice || minPrice;
    this.price.selectedMax = selectedMaxPrice || maxPrice;

    setTimeout(() => {
      this.isStopGetDoctors = false;
    });
  }

  formirationListDoctors(doctors) {
    return doctors.map((doctor) => {
      const {
        firstName,
        lastName,
        middleName,
        specializations,
        price
      } = doctor;

      return {
        ...doctor,
        fullName: `${lastName} ${firstName} ${middleName}`,
        specializations: specializations.map(({ title }) => title).join(', '),
        price
      };
    });
  }

  clearFilters() {
    this.price.min = 0;
    this.price.max = 0;
    this.filters = Object.assign(this.filters, DEFAULT_FILTERS);
  }

  /** Получить выбранные фильтры */
  getSelectedFilters() {
    const list = [];
    const {
      appointmentDateFrom,
      appointmentDateTo,
      showUnscheduledDoctors,
      minimumYearsOfExperience,
      maxPrice,
      minPrice
    } = this.filters;

    for (const type in this.filtersParams) {
      list.push(
        ...this.filtersParams[type]
          .filter((i) => i.value)
          .map((i) => ({
            ...i,
            type,
            fieldFilter: i.type
          }))
      );
    }

    /** Врачи без расписания */
    if (showUnscheduledDoctors) {
      list.push({
        label: 'domains.doctors.filters.view.list.unscheduled',
        type: 'showUnscheduledDoctors'
      });
    }

    /** Дата приема */
    if (appointmentDateFrom || appointmentDateTo) {
      list.push({
        label: 'domains.doctors.filters.appointment.label',
        type: 'appointmentDate'
      });
    }

    /** Стаж работы */
    if (minimumYearsOfExperience) {
      let label = 'domains.doctors.filters.experience.list.five';
      if (minimumYearsOfExperience >= 10) {
        label = 'domains.doctors.filters.experience.list.ten';
      }

      list.push({
        label,
        type: 'minimumYearsOfExperience'
      });
    }

    /** Цена */
    if (minPrice || maxPrice) {
      list.push({
        label: 'common.general-words.price',
        type: 'price'
      });
    }
    return list;
  }

  /** Удалить фильтры */
  deleteTagsFilters(list) {
    this.isStopGetDoctors = true;
    const filters = { ...this.filters };

    for (const filter of list) {
      const { type, id } = filter;

      switch (type) {
        case 'specializations':
          filters.specializationIds = filters.specializationIds.filter(
            (i) => i !== id
          );
          break;

        case 'clinics':
          filters.clinicIds = filters.clinicIds.filter((i) => i !== id);
          break;

        case 'regalies':
          filters[filter.fieldFilter] = filters[filter.fieldFilter].filter(
            (i) => i !== id
          );
          break;

        case 'minimumYearsOfExperience':
          filters.minimumYearsOfExperience = null;
          break;

        case 'appointmentDate':
          filters.appointmentDateFrom = null;
          filters.appointmentDateTo = null;
          break;

        case 'appointmentTypes':
          filters.appointmentTypes = filters.appointmentTypes.filter(
            (i) => i !== id
          );
          break;

        case 'communicationMethods':
          filters.communicationMethods = filters.communicationMethods.filter(
            (i) => i !== id
          );
          break;

        case 'showUnscheduledDoctors':
          filters.showUnscheduledDoctors = false;
          break;

        case 'price':
          filters.minPrice = null;
          filters.maxPrice = null;
          break;
      }
    }

    setTimeout(() => {
      this.isStopGetDoctors = false;
      this.filters = Object.assign(this.filters, filters);
    });
  }

  /** TODO: Тут остаить или вынести
   *  Просто тут требуется перевод
   * */
  formatedListsFromFiltersWithTranslate(list, path) {
    return list
      .filter((i) => i.isAvailable)
      .map((i) => {
        return {
          id: i.key,
          label: this.$t(`${path}.${i.key}`),
          value: i.isSelected,
          type: i.type
        };
      });
  }
}

export default DoctorsService;
