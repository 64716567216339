import { Api } from '~/api';

class MetaApi extends Api {
  constructor(client, $config) {
    const baseUrl = $config.APP_NEW_API + '/seo';
    super(client, baseUrl);

    this.baseURL = baseUrl;
  }

  /**
   * @param {MetaRequest} params
   * @returns {Promise<MetaResponse>}
   */
  async getMeta(params) {
    const { data } = await this.get('/', { params });
    return data;
  }
}

export default MetaApi;
