import { Api } from '@/api/index';

export default class ServiceApi extends Api {
  constructor($axios, $config) {
    const baseUrl = $config.APP_OLD_API;
    super($axios, baseUrl);

    this.GOSUSLUGI_AUTHORIZE_URL = $config.APP_OLD_API + '/v1/esia/Autorize';
    this.GOSUSLUGI_LOGOUT_URL = $config.APP_GOSUSLUGI_LOGOUT_URL;
  }

  async getProfile() {
    const { data } = await this.get('/v1/profile');
    return data;
  }

  async updateProfile(params) {
    const { data } = await this.post('/v1/profile', params, {
      headers: {
        'accept-language': 'ru,en;q=0.9'
      }
    });
    return data;
  }

  /**
   * @param {string} id
   * @return {Promise<ConsultationRequestOld>}
   */
  // async getConsultationRequest(id) {
  //   const { data } = await this.get('/v1/consultation/report', {
  //     params: {
  //       requestId: id
  //     }
  //   });
  //
  //   return data;
  // }

  /**
   * @param {number} consultId
   * @param {string} filename
   * @return {Promise<any>}
   */

  loginGosuslugi() {
    const loginUrl = new URL(this.GOSUSLUGI_AUTHORIZE_URL);

    loginUrl.searchParams.append(
      'redirectUrl',
      `${window.location.origin}/auth/gosuslugi/`
    );

    window.location.href = loginUrl.href;
  }

  logoutGosuslugi() {
    const logoutUrl = new URL(this.GOSUSLUGI_LOGOUT_URL);

    logoutUrl.searchParams.append('redirect_url', window.location.origin);

    window.location.href = logoutUrl.href;
  }
}
