export default class DoctorService {
  constructor(client) {
    this.client = client;
  }

  /**
   * @param {Object} params = {}
   * @param {string} params.search
   * @param {string} params.specialization_id
   */
  get(params = {}) {
    return this.client.post('/doctor/info', { ...params });
  }

  /**
   * @param {Object} params = {}
   * @param {string} params.search
   * @param {string} params.specialization_id
   */
  list(params = {}) {
    return this.client.post('/doctor/list', { ...params });
  }

  /**
   * @param {Object} params = {}
   * @param {string} params.search
   */
  specializationsList(params = {}) {
    return this.client.post('/doctor/specializations', { ...params });
  }

  /**
   * @param {Object} params
   * @param {string} params.doctor_id
   */
  getSchedule(params) {
    return this.client.post('/doctor/schedule', { ...params });
  }
}
