export const BLOOD_TYPE = {
  First: 'pages.account.anketa.blood-type.first', // $t('footer.background-information')
  Second: 'pages.account.anketa.blood-type.second',
  Third: 'pages.account.anketa.blood-type.third',
  Fourth: 'pages.account.anketa.blood-type.fourth'
};

export const RH_FACTOR = {
  Positive: 'pages.account.anketa.positive',
  Negative: 'pages.account.anketa.negative'
};

export const FIELDS = {
  height: {
    title: 'pages.account.anketa.hight-cm',
    placeholder: 'pages.account.anketa.specify-hight'
  },
  weight: {
    title: 'pages.account.anketa.weight-kg',
    placeholder: 'pages.account.anketa.specify-weight'
  },
  chronicDiseases: {
    title: 'pages.account.anketa.chronical-diseases'
  },
  allergies: {
    title: 'pages.account.anketa.allergies'
  },
  bloodType: {
    title: 'pages.account.anketa.blood-type-title'
  },
  rhFactor: {
    title: 'pages.account.anketa.rh-title'
  },
  surgeries: {
    title: 'pages.account.anketa.operations'
  },
  permanentMedications: {
    title: 'pages.account.anketa.permanent-medications'
  }
};

/*
 * isWoman - делаем выборку из medicalQuestionnaireForWomenRequest(только для женщин)
 * constValue - значение нужно брать из константы
 * sex - Пол пользователя. Если пациент женщина, то блоки isWoman не показываем
 */
export const BLOCKS_FIELDS_VIEW = [
  [
    { field: 'allergies' },
    { field: 'chronicDiseases' },
    { field: 'permanentMedications' },
    { field: 'surgeries' }
  ]
];

export const LIST_RADIO_BOOLEAN = [
  { title: 'common.yes-or-no.yes', id: true },
  { title: 'common.yes-or-no.no', id: false }
];
