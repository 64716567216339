<template>
  <div
    class="flex items-end my-2 w-full"
    :class="[!message.incoming ? 'justify-end !pl-20 pr-1.5' : '!pr-20 pl-1.5']"
  >
    <v-chat-message
      :message-id="message.id"
      :is-incoming="message.incoming"
      :type="message.messageType"
      :time-stamp="time"
      :message-options="messageOptions"
      :title="name"
      :extension="extension"
      :size="size"
      :was-read="message.wasRead"
      :is-loading-error="message.isError"
      :is-error="message.isError"
      :is-sending="message.isSending"
      :error-text="$t('domains.consultation.downloading-error')"
      @onMenuSelect="onSelectOption"
      @select="onSelectOption(messageKey)"
    />
  </div>
</template>

<script>
import { VChatMessage } from '@doctis.front/doctis.designsystem';
import { format, parseISO } from '@evd3v/date-fns';
import {
  downloadFile,
  convertBytesToMb,
  filterOptions,
  fileMessageKey
} from '@/services/files/files.helpers';
import {
  FILE_MESSAGE_OPTIONS,
  FILTER_FILE_MESSAGE_OPTIONS
} from '@/services/files/files.const';

export default {
  name: 'SupportChatMessageFile',
  components: {
    VChatMessage
  },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  computed: {
    isIncoming() {
      return this.message.incoming;
    },
    payload() {
      return JSON.parse(this.message.payload);
    },
    extension() {
      if (this.message.isLocalFile)
        return this.payload.name
          .split('.')
          .reverse()[0]
          .toUpperCase();
      else return this.message.extension;
    },
    size() {
      if (this.message.isLocalFile) return convertBytesToMb(this.payload.size);
      else return this.message.size;
    },
    time() {
      if (this.message.isLocalFile)
        return format(parseISO(this.message.createdAt), 'HH:mm');
      else return this.message.time;
    },
    name() {
      if (this.message.isLocalFile) return this.payload.name;
      else return this.message.title;
    },
    messageOptions() {
      return filterOptions({
        message: this.message,
        options: FILE_MESSAGE_OPTIONS,
        filterOptions: FILTER_FILE_MESSAGE_OPTIONS,
        isProgressVisible: this.isProgressVisible,
        isPreview: this.isPreview
      });
    },
    messageKey() {
      return fileMessageKey(this.message);
    }
  },
  methods: {
    downloadFile() {
      const { blob, title } = this.message;
      downloadFile(blob, title);
    },
    onSelectOption(select) {
      if (select.key === 'Download-to-device') {
        this.downloadFile();
      } else if (select.key === 'Download') this.isPreview = false;
      else if (select.key === 'Cancel') {
        this.abortDownloading();
      } else {
        this.$emit('setMessage', {
          selected: select.key,
          messageId: this.message.id
        });
      }
    }
  }
};
</script>
