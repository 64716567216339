import { Api } from '~/api';

class ChatApi extends Api {
  constructor(client, $config) {
    const baseUrl = $config.APP_NEW_API + '/v3';
    super(client, baseUrl);
  }

  /**
   * @param {number | string} consultationId
   * @returns {Promise<Array<ChatMessageModel>>}
   */
  list(consultationId) {
    return this.get('/consultation/chat/message/list', {
      params: {
        consultationId
      }
    });
  }

  /**
   * @param {ChatMessageSendDTO} message
   * @returns {Promise<ChatMessageModel>}
   */
  send(message) {
    return this.post('/consultation/chat/message/send', message);
  }

  read(consultationId) {
    return this.post(`/chat/${consultationId}/markAsRead`);
  }
}

export default ChatApi;
