export default function({ $axiosClient, $auth, $config, $store }, inject) {
  // if (process.browser && $auth.loggedIn) {
  return new Promise((resolve) =>
    import('@/services/core/hub').then(async (HubClient) => {
      // eslint-disable-next-line
      const hubClient = new HubClient.default($auth, $config);

      inject('hubClient', hubClient);

      if ($auth.loggedIn && process.client) {
        await hubClient.init();
      }

      resolve();
    })
  );
}
