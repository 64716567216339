import { STATUSES, TYPE_EVENTS, INITIATOR } from './status.const';
import { MODALS } from '@/services/core/ui/const';

class StatusService {
  constructor(client, hub, $uiService) {
    this.hub = hub;
    this.$uiService = $uiService;
    this.isSubscribed = false;
  }

  static EVENTS = {
    consultation: ['inWork', 'canceled', 'closed', 'created'],
    remoteMonitoringTask: ['coming', 'current', 'completed'],
    referral: ['created']
  };

  updateRmList(cb) {
    for (const status of StatusService.EVENTS.remoteMonitoringTask) {
      this.hub.on(`remoteMonitoringTask.${status}`, async (result) => {
        await cb();
      });
    }
  }

  subscribe() {
    if (this.isSubscribed) return;
    for (const event in StatusService.EVENTS) {
      for (const status of StatusService.EVENTS[event]) {
        this.hub.on(`${event}.${status}`, (result) => {
          const {
            consultationId,
            initiator,
            monitoringId,
            monitoringIdGuid,
            taskIdGuid,
            type,
            referralId
          } = result;

          switch (event) {
            case TYPE_EVENTS.REFERRAL:
              if (type === 'LaboratoryService' && referralId) {
                this.$uiService.showModal(
                  MODALS.REFERRAL_STATUS_UPDATE_EVENT_DIALOG,
                  { props: { id: referralId } }
                );
              }
              break;
            case TYPE_EVENTS.CONSULTATION:
              if (consultationId) {
                if (
                  initiator === INITIATOR.PATIENT &&
                  status === STATUSES.CREATED
                )
                  return;

                this.$uiService.showModal(
                  MODALS.CONSULTATION_STATUS_UPDATE_EVENT_DIALOG,
                  { props: { event: status, id: consultationId } }
                );
              }
              break;

            case TYPE_EVENTS.DM:
              if (monitoringId && status === STATUSES.CURRENT) {
                this.$uiService.showModal(MODALS.CURRENT_TASK_STATUS_MODAL, {
                  props: {
                    event: status,
                    id: monitoringIdGuid,
                    taskId: taskIdGuid
                  }
                });
              }

              break;
          }
        });
      }
    }
    this.isSubscribed = true;
  }
}

export default StatusService;
