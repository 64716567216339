import DispatcherClient from '@/services/dispatcher/index';

export default function(
  { $axiosClient, $hubClient, store, $auth, $config },
  inject
) {
  const client = $axiosClient.instance;

  const dispatcherClient = new DispatcherClient(
    client,
    $hubClient,
    store,
    $config
  );

  inject('dispatcherClient', dispatcherClient);

  if ($auth.loggedIn && process.client) {
    dispatcherClient.init();
  }
}
