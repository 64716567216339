export const MEMBER_TYPE = {
  0: 'Patient',
  1: 'Doctor',
  2: 'Admin', // Пользователь CRM
  Admin: 'Admin' // Пользователь CRM. ID который приходит по сокетам
};

export const MESSAGE_TYPE = {
  TIMESTAMP: 'Timestamp',
  SYSTEM: 'System',
  IMAGE: 'Image',
  FILE: 'File',
  TEXT: 'Text',
  CLOSE_APPEAL: 'CloseAppeal'
};
