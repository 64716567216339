import { setDefaultOptions } from '@evd3v/date-fns';
import ru from '@evd3v/date-fns/locale/ru';
import en from '@evd3v/date-fns/locale/en-US';

const locales = {
  ru,
  en
};

export default function({ $config }) {
  const locale = $config.APP_LOCALE;
  setDefaultOptions({ locale: locales[locale] });
}
