export default class ConsultationService {
  constructor(client) {
    this.client = client;
  }

  /**
   * @param {FormData} formData
   * @param {string} formData.doctor_id
   * @param {string} formData.unix_time
   * @param {string} formData.message
   */
  create(formData) {
    return this.client.post('/consultation/create', formData, {
      headers: {
        ContentType: 'multipart/form-data'
      }
    });
  }

  /**
   * @param {Object} params
   * @param {string} params.message
   * @param {string} params.consultation_id
   */
  cancel(params) {
    return this.client.post('/consultation/cancel', { ...params });
  }

  /**
   * @param {Object} params
   * @param {string} params.doctor_id
   * @param {string} params.unix_time
   * @param {string} params.message
   */
  book(params) {
    return this.client.post('/consultation/booking/lock', { ...params });
  }

  /**
   * @param {Object} params
   * @param {string} params.consultation_id
   */
  bookConfirm(params) {
    return this.client.post('/consultation/booking/confirm', { ...params });
  }

  /**
   * @param {Object} params
   * @param {string} params.from
   * @param {string} params.to
   */
  list(params) {
    // return this.client.post('/consultation/list', { ...params });
    return this.client.get('/event/list', { ...params });
  }

  /**
   * @param {String} consultation_id
   */
  get(id) {
    return this.client.post('/consultation/info', {
      consultation_id: id
    });
  }

  /**
   * @param {Object} params
   * @param {string} params.from
   * @param {string} params.to
   */
  listInWork(params) {
    return this.client.post('/consultation/list/in-work', { ...params });
  }

  /**
   * @param {Object} params
   * @param {string} params.from
   * @param {string} params.to
   */
  listClosed(params) {
    return this.client.post('/consultation/list/closed', { ...params });
  }
}
