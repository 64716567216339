import { Api } from '~/api';
import { USER_TYPE } from '@/consts';

class AuthApi extends Api {
  constructor(client, $config) {
    const baseUrl = $config.APP_NEW_API + '/v3/auth';
    super(client, baseUrl);
    this.esiaLogoutUrl = $config.APP_GOSUSLUGI_LOGOUT_URL;

    this.baseURL = baseUrl;
  }

  redirectToEsia() {
    const loginUrl = new URL(this.baseURL + '/esia/login');

    const redirectUrl = `${window.location.href.split('?')[0]}`;

    loginUrl.searchParams.append('redirectUrl', redirectUrl);
    loginUrl.searchParams.append('userType', USER_TYPE.COMMERCIAL.toString());

    window.location.href = loginUrl.href;
  }

  async sendSmsCode(payload) {
    const {
      data: { data }
    } = await this.post('/send-sms-code', payload);
    return data;
  }

  async loginByPhone(payload) {
    const {
      data: { data }
    } = await this.post('/patient/login', payload);
    return data;
  }

  /** ESIA */
  loginEsia(params = []) {
    const loginUrl = new URL(`${this.baseURL}/esia/login`);

    for (const [key, value] of Object.entries(params)) {
      loginUrl.searchParams.append(key, value);
    }
    loginUrl.searchParams.append('userType', USER_TYPE.COMMERCIAL.toString());
    window.location.href = loginUrl.href;
  }

  logoutEsia(params) {
    const logoutUrl = new URL(this.esiaLogoutUrl);

    for (const [key, value] of Object.entries(params)) {
      logoutUrl.searchParams.append(key, value);
    }
    logoutUrl.searchParams.append('userType', USER_TYPE.COMMERCIAL.toString());
    window.location.href = logoutUrl.href;
  }
}

export default AuthApi;
