import ReferralAnalysisService from '@/services/referral-analysis/referral-analysis.service';

export default function({ app, $axios, $auth, $axiosClient, $config }, inject) {
  const client = $axiosClient.instance;

  const referralAnalysisService = new ReferralAnalysisService(
    app,
    client,
    $config
  );
  inject('referralAnalysisService', referralAnalysisService);
}
