<template>
  <div class="bg-v-ui-root-monochrome-5">
    <vc-application-notification />
    <u-navbar :logged-in="$auth.loggedIn" :moms-school="access" />
    <nuxt />
    <u-footer />

    <client-only>
      <SystemModals />
    </client-only>

    <common-modal-container />
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import VcApplicationNotification from '../components/domains/application/VcApplicationNotification.vue';
import SystemModals from '../components/modal/SystemModals.vue';
import CommonModalContainer from '../components/common/CommonModalContainer.vue';
import UFooter from '@/components/ui/organisms/footer/UFooter';
import UNavbar from '@/components/ui/organisms/navbar/UNavbar';

export default {
  name: 'MainLayout',
  components: {
    CommonModalContainer,
    SystemModals,
    VcApplicationNotification,
    UFooter,
    UNavbar
  },
  mounted() {
    this.setIsMobile();
    if (this.$auth.loggedIn) {
      this.$statusService.subscribe();
      this.checkAccess();
    }
    window.addEventListener('resize', this.setIsMobile);

    if (this.$route.query?.auth) {
      this.$store.dispatch('modules/auth/setAuthorizationFlowState');
    }
  },
  methods: {
    ...mapMutations(['setIsMobile']),
    ...mapActions('modules/moms-school', ['checkAccess'])
  },
  computed: {
    ...mapState('modules/moms-school', ['access'])
  }
};
</script>
