import MedicalQuestionnaireService from '@/services/medical-questionnaire/medical-questionnaire.service';

export default function({ $axiosClient, $config }, inject) {
  const client = $axiosClient.instance;

  const medicalQuestionnaireService = new MedicalQuestionnaireService(
    client,
    $config
  );
  inject('medicalQuestionnaireService', medicalQuestionnaireService);
}
