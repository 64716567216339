import AxiosClient from '@/services/core/axios';

export default function({ $axios, app, $config, $sentry, $uiService }, inject) {
  const axiosClient = new AxiosClient(
    $axios,
    app,
    $config,
    $sentry,
    $uiService
  );

  inject('axiosClient', axiosClient);
}
