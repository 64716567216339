import { Api } from '~/api';

class AnalysisApi extends Api {
  constructor(client, $config) {
    const baseUrl = `${$config.APP_NEW_API}`;
    super(client, baseUrl);

    this.baseURL = baseUrl;
  }

  /**
   * Запрос детальной информации
   */
  async getAnalysis(params) {
    const { data } = await this.get(`/v3/laboratory-service-result-root/info`, {
      params
    });
    return data;
  }

  /**
   * Загрузить файлы результатов лаб-х исследований
   */
  async uploadResultAnalysis(files, id) {
    const { data } = await this.post(
      `/v3/laboratory-service-result-root/upload-result?id=${id}`,
      files,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    );
    return data;
  }

  /**
   * Скачать файл результат лаб-х исследований
   */
  async downloadFile(fileId) {
    const { data } = await this.get(
      `/v3/laboratory-service-result-root/download-result?file_id=${fileId}`,
      {
        responseType: 'arraybuffer'
      }
    );

    return data;
  }

  /**
   * Удалить файл результата лаб-х исследований
   */
  async deleteResultAnalysis(fileId) {
    const { data } = await this.delete(
      `/v3/laboratory-service-result-root/delete-result`,
      {
        params: {
          file_id: fileId
        }
      }
    );
    return data;
  }

  /**
   * Перевести в статус Отменен
   */
  async cancelPurchaseAnalysis(id) {
    const { data } = await this.post(
      `/v3/laboratory-service-result-root/cancel?id=${id}`
    );
    return data;
  }

  /**
   * Перевести в статус Завершен
   */
  async confirmResultAnalysis(id) {
    const { data } = await this.post(
      `/v3/laboratory-service-result-root/complete?id=${id}`
    );
    return data;
  }

  /**
   * Оплатить заказ
   */
  async createPayture(params) {
    const { data } = await this.post(`/payture/check`, params);
    return data;
  }
}

export default AnalysisApi;
