import VtbApi from '@/services/vtb/vtb.api';
import {
  convertBlobToBase64,
  createBlobFromArrayBuffer
} from '@/services/files/files.helpers';

class VtbService {
  constructor(client, $config, i18n) {
    this.api = new VtbApi(client, $config);
    this.$t = i18n.t.bind(i18n);
  }

  /** VTB - Соглашения */
  async vtbAgreementList() {
    const { data } = await this.api.vtbAgreementList();

    const agreements = data.map(async (i) => {
      const file = await this.vtbAgreementGetFile(i.id);
      return {
        ...i,
        id: i.type,
        idAgreement: i.id,
        title: this.$t(`pages.dashboard.modal.agreements.${i.type}.text`),
        idKey: 'id',
        size: 'm',
        link: {
          text: this.$t(`pages.dashboard.modal.agreements.${i.type}.link`),
          href: file,
          name: `${i.type}.pdf`
        }
      };
    });

    return await Promise.all(agreements);
  }

  async vtbAgreementGetFile(id) {
    const file = await this.api.vtbAgreementGetFile(id);
    const blob = createBlobFromArrayBuffer(file, 'application/pdf');
    return await convertBlobToBase64(blob);
  }

  async vtbAgreementSign(agreements) {
    await this.api.vtbAgreementSign(agreements.map((i) => i.idAgreement));
  }

  async vtbSendFiles(files, policyId) {
    const formData = new FormData();
    formData.append('PolicyNumber', policyId);
    for (const { file } of files) {
      formData.append('PolicyFiles', file);
    }

    await this.api.vtbSendFiles(formData);
  }
}

export default VtbService;
