export default async function({ $auth, route, redirect, store }) {
  const {
    token,
    refreshToken,
    access_token: access,
    refresh_token: refresh,
    ...query
  } = route.query;

  const externalToken = token || access;
  const externalRefreshToken = refreshToken || refresh;

  if (externalToken && externalRefreshToken) {
    if (!$auth.loggedIn) {
      try {
        await $auth.setUserToken(externalToken, externalRefreshToken);
        return redirect(route.path, query);
      } catch (e) {
        return redirect('/auth/');
      }
    } else {
      try {
        await $auth.logout();
        await $auth.setUserToken(externalToken, externalRefreshToken);
        return redirect(route.path, query);
      } catch (e) {
        return redirect('/auth/');
      }
    }
  }

  if (route.query?.auth) {
    setTimeout(() => {
      store.dispatch('modules/auth/setAuthorizationFlowState');
    }, 100);
  }
}
