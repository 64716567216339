export const TOASTS = {
  ADDRESS_COPIED: 'address-copied',
  NUMBER_COPIED: 'number-copied',
  YOU_FIND_EVENT_IN_LIST: 'you-find-event-in-list',
  LINK_COPIED: 'link-copied',
  LINK_COPIED_ERROR: 'link-copied-error',
  CHOICE_CONSULTATION_TIME: 'choice-consultation-time',
  MESSAGE_COPIED: 'message-copied',
  RECORDING_CANCELED: 'recording-canceled',
  COPIED: 'copied'
};
