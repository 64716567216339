<template>
  <div id="support-chat-header">
    <div
      class="md:flex hidden items-center justify-between h-10 px-4 bg-v-ui-root-main text-v-ui-text-monochrome-0"
    >
      <div>{{ $t('domains.support.support') }}</div>
      <v-icon name="s/cross" class="cursor-pointer" @click="close" />
    </div>
    <div
      class="md:hidden flex items-center h-14 bg-v-ui-background-monochrome-5 px-2"
    >
      <v-icon
        name="m/arrow_left_chevron"
        class="cursor-pointer stroke-v-ui-icon-monochrome"
        @click="close"
      />
      <div class="flex items-center ml-2">
        <div
          class="rounded-full flex items-center justify-center h-9 w-9 bg-v-ui-light-service-info-monochrome"
        >
          <v-icon
            name="s/support"
            class="stroke-v-ui-icon-monochrome-27 cursor-pointer"
          />
        </div>
        <div class="ml-2">
          <div class="font-medium text-v-ui-text-monochrome leading-4	mb-1">
            {{ $t('domains.support.support') }}
          </div>
          <div class="text-sm text-v-ui-text-monochrome-55 leading-none">
            {{ $t('domains.support.every-day') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VIcon } from '@doctis.front/doctis.designsystem';

export default {
  name: 'SupportHeader',
  components: {
    VIcon
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>
