import { v4 as uuidv4 } from 'uuid';
import { fileCategories, fileTypes } from '@/consts';
import { convertBlobToBase64 } from '@/helpers';

export const state = () => ({
  duty: null,
  files: []
});

export const mutations = {
  setDuty(state, duty) {
    state.duty = duty;
  },
  setFiles(state, files) {
    const newFiles = files.filter((file) => {
      const idx = state.files.findIndex((oldFile) => {
        return oldFile.Name === file.Name;
      });
      return idx === -1;
    });

    const mappedFiles = newFiles.map((file) => ({
      ...file,
      blob: null,
      base64: null
    }));
    state.files = [...state.files, ...mappedFiles];
  },
  updateFile(state, file) {
    const index = state.files.findIndex((item) => item.Name === file.Name);
    if (index !== -1) state.files.splice(index, 1, file);
  },
  clearConsultationState(state) {
    state.duty = null;
    state.files = [];
  }
};

export const actions = {
  async uploadFiles({ state }, { consultation, files }) {
    const mappedFiles = files.map((file) => {
      const fileType = file.type.split('/')[0];
      const category =
        fileCategories.find((type) => type === fileType) || 'document';
      return {
        id: uuidv4(),
        category,
        mimeType: file.type,
        file
      };
    });

    const fileTag = fileTypes.OTHER;
    const promises = mappedFiles.map((item) => {
      const { file, category, mimeType } = item;
      const formData = new FormData();
      formData.append('file', file);
      return this.$fileApi.saveFile(formData, category, mimeType, fileTag.id);
    });
    const filenames = await Promise.all(promises);

    const addFilesPromises = await filenames.map((filename) => {
      return this.$coreApi.addFileToConsultation(consultation.id, filename);
    });

    await Promise.all(addFilesPromises);

    return filenames;
  },
  async getConsultationFiles({ state, commit, dispatch }, consultation) {
    const folder = consultation?.folderOtherId;

    const files = await this.$fileApi.getFilesByFolderName(folder);
    const filteredFiles =
      files.filter((file) => {
        return !state.files.find((item) => item.Name === file.Name);
      }) || [];

    commit('setFiles', filteredFiles);
    // console.log('###', filteredFiles);
    filteredFiles.forEach((file) => {
      dispatch('getFileBlob', file);
    });
  },
  async getFileBlob({ commit }, file) {
    if (window) {
      const blob = await this.$fileApi.getFileByFilename(
        file.Name,
        file.MimeType
      );
      if (blob) {
        const base64 = await convertBlobToBase64(blob);
        const newFile = { ...file, base64, blob };
        commit('updateFile', newFile);
      }
    }
  },
  resetConsultation({ commit }) {
    commit('clearConsultationState');
  }
};

export default {
  state,
  actions,
  mutations
};
