<template>
  <div class="empty-layout">
    <nuxt />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'Empty',
  mounted() {
    this.setIsMobile();
    window.addEventListener('resize', this.setIsMobile);
  },
  methods: {
    ...mapMutations(['setIsMobile'])
  }
};
</script>
