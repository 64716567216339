export const AUTH_STEPS = {
  PHONE_STEP: 'phone-step',
  SUBMIT_PHONE_STEP: 'submit-phone-step',
  USER_DATA_STEP: 'patient-data-step'
};

export const AUTH_ERROR_TYPE = {
  SEND_SMS_TIMEOUT: 1,
  INVALID_NUMBER: 2,
  TO_MANY_ATTEMPTS: 3,

  GOSUSLUGI_ERROR: 100
};
