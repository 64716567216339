import Vue from 'vue';
import RemoteMonitoringPlugin from '@doctis.front/library.vuecomponent.patientmonitoring';

export default function({ $axiosClient, $hubClient, $config }) {
  const apiClient = {
    client: $axiosClient.instance,
    options: {
      baseUrl: $config.APP_NEW_API
    }
  };

  Vue.use(RemoteMonitoringPlugin, {
    apiClient,
    hubClient: $hubClient,
    locale: $config.APP_LOCALE
  });
}
