class VarioqubService {
  constructor(YM_ID, $store) {
    this.YM_ID = YM_ID;
    this.loadScript(YM_ID);
    this.store = $store;
  }

  loadScript(YM_ID) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      (function(e, x, pe, r, i, me, nt) {
        e[i] = e[i] || function() {
          (e[i].a = e[i].a || []).push(arguments)
        },
        me = x.createElement(pe),
        me.async = 1,
        me.src = r,
        nt = x.getElementsByTagName(pe)[0],
        nt.parentNode.insertBefore(me, nt)
      })(window, document, 'script', 'https://abt.s3.yandex.net/expjs/latest/exp.js', 'ymab');
      ymab('metrika.${YM_ID}', 'init'/*, {clientFeatures}, {callback}*/);
    `;
    document.body.appendChild(script);
  }

  getFlags() {
    window.ymab(`metrika.${this.YM_ID}`, 'getFlags', (flags) => {
      this.store.dispatch('setABFlags', flags);
    });
  }
}

export default VarioqubService;
