<template>
  <v-chat-input
    id="support-chat-input"
    :placeholder="$t('common.resizable-textarea.placeholder')"
    @onFileUpload="onFileUpload"
    @onMessageSend="onMessageSend"
  />
</template>

<script>
import { VChatInput } from '@doctis.front/doctis.designsystem';
import { v4 as uuidv4 } from 'uuid';
import { MESSAGE_TYPE } from '@/services/support-chat/support.const';

export default {
  name: 'SupportChat',
  components: {
    VChatInput
  },
  data() {
    return {
      text: ''
    };
  },
  methods: {
    async onFileUpload(files) {
      try {
        if (files) {
          await files.forEach((file) => {
            this.$supportChatService.sendFiles([file]);
          });
          this.$emit('filesCount', files.length);
        }
        this.$refs.fileInput.value = null;
      } catch (e) {
        this.$sentry.captureException(e);
      }
    },
    onMessageSend(value) {
      this.text = value;
      if (this.text) {
        try {
          this.$supportChatService.sendMessage(
            {
              text: this.text,
              messageType: MESSAGE_TYPE.TEXT,
              payload: null,
              member: { memberType: 'Patient' },
              createdAt: new Date().toISOString(),
              isError: false,
              isSending: true,
              id: uuidv4()
            },
            true
          );
          this.$refs.resize.$refs.textarea.textContent = '';
          this.text = '';
        } catch (e) {
          this.$sentry.captureException(e);
        }
      }
    }
  }
};
</script>
