export default function({ route, store, redirect }) {
  // const { user } = store.state.auth;
  //
  // if (user && route.path !== '/auth/gosuslugi') {
  //   if (!user.IsProfileFilledIn) {
  //     return redirect('/auth/personal');
  //   }
  //
  //   if (user.IsProfileFilledIn && route.name === 'auth-personal') {
  //     return redirect('/dashboard');
  //   }
  // }
}
