export const STATUS = {
  NEW: 'New',
  ACCEPTED: 'Accepted',
  PROVIDED: 'Provided',
  IN_PROGRESS: 'ConsultationInProgress',
  DECLINED: 'Declined',
  OVERDUE: 'Overdue',
  UNUSED: 'Unused'
};

export const TYPE_PROGRESS = {
  NEW: 'New',
  PROVIDED: 'ConsultationProvided',
  IN_PROGRESS: 'ConsultationInProgress',
  DECLINED: 'Declined',
  UNUSED: 'Unused'
};
