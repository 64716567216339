export const state = () => ({
  activeStories: [],
  allStories: [],
  totalNumberOfStories: 0
});

export const actions = {
  async fetchActiveStories({ commit }) {
    const {
      stories,
      totalNumberOfStories
    } = await this.$storyService.listActive();

    commit('SET_ACTIVE_STORIES', stories);
    commit('SET_TOTAL_NUMBER_OF_STORIES', totalNumberOfStories);
  },
  async fetchAllStories({ state, commit, dispatch }) {
    let stories = await this.$storyService.list();

    /*
      Для синхронизации просмотренных историй (в рамках одной сессии и без авторизации)
     */

    if (!this.$auth.loggedIn) {
      stories = stories.map((story) => {
        const idx = state.allStories.findIndex(
          (prevStory) => prevStory.id === story.id
        );

        if (idx !== -1) {
          return { ...story, ...state.allStories[idx] };
        }

        return story;
      });
    }

    commit('SET_ALL_STORIES', stories);
  },
  syncStory({ commit, state }, story) {
    const { activeStories, allStories } = state;

    if (activeStories.length) {
      const index = activeStories.findIndex((s) => {
        return s.id === story.id;
      });

      if (index !== -1) {
        const newStories = [...activeStories];
        newStories.splice(index, 1, story);

        commit('SET_ACTIVE_STORIES', newStories);
      }
    }

    if (allStories.length) {
      const index = allStories.findIndex((s) => s.id === story.id);

      if (index !== -1) {
        const newStories = [...allStories];
        newStories.splice(index, 1, story);

        commit('SET_ALL_STORIES', newStories);
      }
    }
  }
};

export const mutations = {
  SET_ACTIVE_STORIES(state, stories) {
    state.activeStories = [...stories];
  },
  SET_ALL_STORIES(state, stories) {
    state.allStories = stories;
  },
  SET_TOTAL_NUMBER_OF_STORIES(state, count) {
    state.totalNumberOfStories = count;
  }
};
