import PaymentApi from './payment.api';

class PaymentService {
  constructor(client, hub, $config) {
    this.api = new PaymentApi(client, $config);
    this.hub = hub;
  }

  async pay(payload) {
    const { data } = await this.api.pay(payload);
    return data;
  }

  async info(payload) {
    const { data } = await this.api.info(payload);
    return data;
  }
}

export default PaymentService;
