<template>
  <div class="flex flex-col min-h-screen">
    <u-navbar :logged-in="$auth.loggedIn && $auth.user" />
    <div
      class="flex items-center justify-center flex-1 bg-v-ui-root-monochrome-5"
    >
      <nuxt />
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'Empty',
  components: {
    UNavbar: () => import('@/components/ui/organisms/navbar/UNavbar')
  },
  mounted() {
    this.setIsMobile();
    window.addEventListener('resize', this.setIsMobile);
  },
  methods: {
    ...mapMutations(['setIsMobile'])
  }
};
</script>
