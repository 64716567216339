export class Api {
  constructor($axios, baseURL) {
    this.client = $axios;
    this.baseURL = baseURL;
  }

  get(url, ...args) {
    return this.client.get(this.baseURL + url, ...args);
  }

  post(url, ...args) {
    return this.client.post(this.baseURL + url, ...args);
  }

  put(url, ...args) {
    return this.client.put(this.baseURL + url, ...args);
  }

  patch(url, ...args) {
    return this.client.patch(this.baseURL + url, ...args);
  }

  delete(url, ...args) {
    return this.client.delete(this.baseURL + url, ...args);
  }
}
