<template>
  <footer class="container lg:py-[72px] py-12">
    <div
      v-if="!simples"
      class="lg:justify-between grid lg:grid-cols-4 lg:gap-8 gap-6"
    >
      <div v-for="(section, idx) in sections" :key="idx">
        <p class="title-20-medium-500 text-v-ui-text-monochrome">
          {{ section.title }}
        </p>
        <div
          class="lg:mt-4 mt-2 flex flex-wrap lg:gap-4 gap-x-4 gap-y-2 lg:flex lg:flex-col"
        >
          <u-link
            v-for="item in section.links"
            v-show="item.enabled"
            :key="item.title"
            :inner="item.inner"
            :link="!item.inner ? item.link : { path: item.link.path }"
            class="text-v-ui-text-monochrome-55 lg:title-16-medium-500 text-14-reg-400"
          >
            {{ item.title }}
          </u-link>
        </div>
      </div>
    </div>
    <div
      class="lg:justify-between lg:mt-16 lg:grid lg:grid-cols-4 lg:gap-8 gap-6"
      :class="{ '!mt-0': simples }"
    >
      <div class="lg:w-40 flex flex-col justify-between">
        <u-link class="block lg:w-40" :link="{ path: '/' }">
          <component
            :is="logo"
            :class="[{ 'w-[159px]': isMobile }, { '!mt-0': simples }]"
            class="mt-8 lg:mt-0"
          />
        </u-link>
        <div class="flex gap-2 lg:mt-5 mt-4 h-10 lg:flex-col lg:h-auto">
          <u-link v-for="(store, idx) in stores" :key="idx" :link="store.link">
            <nuxt-img
              :alt="store.alt"
              :src="store.img"
              loading="lazy"
              format="webp"
            />
          </u-link>
        </div>
      </div>

      <div class="flex flex-col mt-6 lg:mt-0">
        <p class="text-v-ui-text-monochrome-27 text-14-reg-400">
          {{ $t('footer.background-information') }}
        </p>
        <a
          class="mt-1 text-v-ui-root-main title-20-medium-500"
          href="tel:88005000299"
        >
          8 800 500 0299
        </a>
        <a
          class="mt-1 text-v-ui-root-main title-20-medium-500"
          href="tel:+74994901713"
        >
          +7 499 490 17 13
        </a>

        <div
          v-if="isFooterLinkDisabled"
          class="flex gap-2 lg:mt-auto mt-3 lg:h-10 h-8 lg:flex-row lg:h-auto"
        >
          <div v-for="(brand, idx) in brands" :key="idx">
            <a :href="brand.link" :alt="brand.alt">
              <v-icon :name="brand.name" />
            </a>
          </div>
        </div>
      </div>

      <div>
        <div class="flex flex-col mt-4 lg:mt-0">
          <p class="text-v-ui-text-monochrome-27 text-14-reg-400">
            {{ $t('footer.support') }}
          </p>
          <a
            class="text-v-ui-root-main title-20-medium-500"
            href="mailto:app.support@doctis.ru"
          >
            app.support@doctis.ru
          </a>
        </div>

        <div class="flex flex-col mt-4">
          <p class="text-v-ui-text-monochrome-27 text-14-reg-400">
            {{ $t('footer.cooperation') }}
          </p>
          <a
            class="text-v-ui-root-main title-20-medium-500"
            href="mailto:info@doctis.ru"
          >
            info@doctis.ru
          </a>
        </div>

        <div class="flex flex-col mt-4">
          <p class="text-v-ui-text-monochrome-27 text-14-reg-400">
            {{ $t('footer.promotion') }}
          </p>
          <a
            class="text-v-ui-root-main title-20-medium-500"
            href="mailto:pr@doctis.ru"
          >
            pr@doctis.ru
          </a>
        </div>
      </div>

      <div class="flex gap-4 flex-col lg:mt-0 mt-6">
        <div class="flex flex-col gap-2">
          <template v-if="isFooterLinkDisabled && !simples">
            <u-link link="https://sk.ru">
              <nuxt-img
                :src="logoSk"
                class="h-10 lg:w-[140px] w-[155px]"
                loading="lazy"
                format="webp"
                alt="logo sk"
              />
            </u-link>
          </template>
          <p class="text-14-reg-400 text-v-ui-text-monochrome-27 lg:text-left">
            © 2016 — {{ currentYear }} <br />
            {{ $t('footer.resident-skolkovo') }}<br />
            {{ $t('footer.doctis') }}
          </p>
        </div>
        <template v-if="isFooterLinkDisabled">
          <p class="text-14-reg-400 text-v-ui-text-monochrome-27 lg:text-left">
            <a :href="pathToLicense" download target="_blank" class="underline">
              {{ $i18n.separateToArray('footer.license', 0) }}
            </a>
            <span class="whitespace-pre-line">{{
              $i18n.separateToArray('footer.license', 1)
            }}</span>
          </p>
        </template>
        <p class="text-14-reg-400 text-v-ui-text-monochrome-27 lg:text-left">
          <a :href="pathToPersonalData" download target="_blank">
            {{ $t('footer.personal-data') }}
          </a>
        </p>
      </div>
    </div>
    <div
      v-if="!simples"
      class="lg:mt-16 lg:columns-4 columns-1 mt-8 lg:gap-8 gap-6"
    >
      <div
        v-for="(list, key) of specializations"
        :key="key"
        class="lg:mb-8 mb-6 h-auto break-inside-avoid"
      >
        <div class="title-20-medium-500 text-v-ui-text-main mb-3">
          {{ key }}
        </div>

        <div class="text-14-reg-400 text-v-ui-text-monochrome-55">
          <nuxt-link
            v-for="(item, index) of list"
            :key="index"
            :to="item.link"
            class="mb-2 block last:mb-0"
          >
            {{ item.title }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { VIcon } from '@doctis.front/doctis.designsystem';
import { PATH_TO_LICENSE, PATH_TO_PERSONAL_DATA } from '@/consts/';
import ULink from '@/components/ui/atoms/link/ULink';
import LogoRuIcon from '@/assets/svg/logos/doctis-ru.svg?inline';
import LogoEngIcon from '@/assets/svg/logos/doctis-eng.svg?inline';

export default {
  name: 'UFooter',
  components: {
    ULink,
    VIcon,
    LogoRuIcon,
    LogoEngIcon
  },
  props: {
    simples: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      logoSk: '/images/components/ui/organisms/footer/Logo_Sk_resident.webp',
      brands: [
        {
          alt: 'Дзен',
          name: 'brand/dzen',
          link: 'https://dzen.ru/doctis'
        },
        {
          alt: 'Доктис. Врачи онлайн @Telegram',
          name: 'brand/telegram',
          link: 'https://t.me/doctis_ru'
        },
        {
          alt: 'Доктис. Врачи онлайн @Oдноклассники.ru',
          name: 'brand/ok',
          link: 'https://ok.ru/doctis'
        },
        {
          alt: 'Доктис. VKонтакте',
          name: 'brand/vk',
          link: 'https://vk.com/doctis'
        }
      ],
      specializations: {}
    };
  },
  computed: {
    ...mapState(['isMobile']),
    FILE_PREFIX() {
      return this.$config.APP_INTERNATIONAL_STAND ? '_en' : '';
    },
    logo() {
      return this.$config.APP_INTERNATIONAL_STAND ? LogoEngIcon : LogoRuIcon;
    },
    isFooterLinkDisabled() {
      return !this.$config.APP_INTERNATIONAL_STAND;
    },
    pathToLicense() {
      return PATH_TO_LICENSE;
    },
    pathToPersonalData() {
      return PATH_TO_PERSONAL_DATA;
    },
    sections() {
      return [
        {
          title: this.$t('footer.services'),
          links: [
            {
              title: this.$t('footer.question-doctor'),
              inner: true,
              link: { path: '/dashboard', query: { to: 'duty' } },
              enabled: this.isFooterLinkDisabled
            },
            {
              title: this.$t('footer.online-consultation'),
              inner: true,
              link: { path: '/dashboard' },
              enabled: true
            },
            {
              title: this.$t('footer.take-tests'),
              inner: true,
              link: { path: '/analysis' },
              enabled: true
            },
            {
              title: this.$t('footer.face-to-face'),
              inner: true,
              link: { path: '/ochnoe-poseschenie-vracha' },
              enabled: this.isFooterLinkDisabled
            }
          ]
        },
        {
          title: this.$t('footer.products'),
          links: [
            // {
            //   title: 'Фетальный монитор',
            //   inner: true,
            //   link: { path: '/fetalnyj-monitor' }
            // },
            // {
            //   title: this.$t('footer.female-diagnostics'),
            //   link:
            //     'https://www.doctis.ru/landings/check-up-dlya-zhenshchin-obsledovanie',
            //   enabled: this.isFooterLinkDisabled
            // },
            {
              title: this.$t('footer.telemedicine-centers'),
              inner: true,
              link: { path: '/telemed-centers' },
              enabled: this.isFooterLinkDisabled
            },
            {
              title: this.$t('footer.activate-policy'),
              inner: true,
              link: { path: '/account/insurance' },
              enabled: true
            },
            {
              title: this.$t('footer.apps-yourly'),
              link: 'https://yourly.ru',
              enabled: true
            },
            {
              title: this.$t('footer.about-telemedicine'),
              link: '/promo/consultations',
              enabled: true
            }
          ]
        },
        {
          title: this.$t('footer.partners'),
          links: [
            {
              title: this.$t('footer.lk-doctor'),
              link: 'https://doctor.doctis.ru/auth',
              enabled: true
            }
            // {
            //   title: 'Страховым компаниям',
            //   link:
            //     'https://www.doctis.ru/%D1%82%D0%B5%D0%BB%D0%B5%D0%BC%D0%B5%D0%B4%D0%B8%D1%86%D0%B8%D0%BD%D0%B0/%D0%B4%D0%BB%D1%8F%20%D1%81%D1%82%D1%80%D0%B0%D1%85%D0%BE%D0%B2%D1%8B%D1%85%20%D0%BA%D0%BE%D0%BC%D0%BF%D0%B0%D0%BD%D0%B8%D0%B9'
            // },
            // {
            //   title: 'Медицинским учреждениям',
            //   link:
            //     'https://www.doctis.ru/%D1%82%D0%B5%D0%BB%D0%B5%D0%BC%D0%B5%D0%B4%D0%B8%D1%86%D0%B8%D0%BD%D0%B0/%D0%B4%D0%BB%D1%8F%20%D0%BC%D0%B5%D0%B4%D0%B8%D1%86%D0%B8%D0%BD%D1%81%D0%BA%D0%B8%D1%85%20%D0%BE%D1%80%D0%B3%D0%B0%D0%BD%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%B9'
            // }
          ]
        },
        {
          title: this.$t('footer.service'),
          links: [
            {
              title: this.$t('footer.faq'),
              inner: true,
              link: { path: '/faq' },
              enabled: true
            },
            {
              title: this.$t('footer.about-doctis'),
              inner: true,
              link: { path: '/about' },
              enabled: true
            },
            {
              title: this.$t('footer.articles'),
              link: 'https://www.doctis.ru/info',
              enabled: this.isFooterLinkDisabled
            }
          ]
        }
      ];
    },
    stores() {
      const urlPath = '/images/components/ui/organisms/footer/';
      return [
        {
          alt: 'App store',
          img: `${urlPath}/app-store${this.FILE_PREFIX}.webp`,
          link:
            'https://apps.apple.com/ru/app/%D0%B4%D0%BE%D0%BA%D1%82%D0%B8%D1%81-%D0%B2%D1%80%D0%B0%D1%87%D0%B8-%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD/id1251825134'
        },
        {
          alt: 'Google play',
          img: `${urlPath}/google-play${this.FILE_PREFIX}.webp`,
          link:
            'https://play.google.com/store/apps/details?id=ru.medotrade.mobile.patientdoctis&hl=ru'
        }
      ];
    }
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.query?.authorization) {
          this.setAuthorizationFlowState(true);
        }
      },
      immediate: true
    }
  },
  async fetch() {
    const { data } = await this.$coreApi.getSpecializations();
    this.specializations = data.specializations.reduce((acc, item) => {
      const firstLetter = item.title.charAt(0).toUpperCase();
      if (!acc[firstLetter]) acc[firstLetter] = [];
      let link = `/specializations/${item?.seo?.slug}`;

      if (!item.hasAvailableSlot) {
        link += '?showUnscheduledDoctors=true';
      }

      acc[firstLetter].push({
        link,
        title: item.title
      });
      return acc;
    }, {});

    const sortedKeys = Object.keys(this.specializations).sort();
    const sortedSpecializations = {};
    sortedKeys.forEach((key) => {
      sortedSpecializations[key] = this.specializations[key];
    });

    this.specializations = sortedSpecializations;
  },
  methods: {
    ...mapActions('modules/auth', ['setAuthorizationFlowState']),
    async getSpecializations() {
      const { data } = await this.$coreApi.getSpecializations();
      this.specializations = data.specializations.reduce((acc, item) => {
        const firstLetter = item.title.charAt(0).toUpperCase();
        if (!acc[firstLetter]) acc[firstLetter] = [];
        let link = `/specializations/${item?.seo?.slug}`;

        if (!item.hasAvailableSlot) {
          link += '?showUnscheduledDoctors=true';
        }

        acc[firstLetter].push({
          link,
          title: item.title
        });
        return acc;
      }, {});

      const sortedKeys = Object.keys(this.specializations).sort();
      const sortedSpecializations = {};
      sortedKeys.forEach((key) => {
        sortedSpecializations[key] = this.specializations[key];
      });

      this.specializations = sortedSpecializations;
    }
  }
};
</script>

<style lang="scss"></style>
