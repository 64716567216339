import AmplitudeService from '../services/metrika/amplitude.service';

export default function({ $config, $auth }, inject) {
  const amplitudeInstance = new AmplitudeService(
    $config.APP_AMPLITUDE_API_KEY,
    $auth
  );

  inject('amplitude', amplitudeInstance);
}
