import qs from 'query-string';
import { createBlobFromArrayBuffer } from '@/services/files/files.helpers';
import { Api } from '@/api/index';
import { filterEmptyParameters } from '@/helpers';
import { createCancelTokenHandler } from '@/services/core/axios/cancel-token';

export default class CoreApi extends Api {
  constructor($axios, $config, $store) {
    const baseUrl = $config.APP_NEW_API;

    super($axios, baseUrl);

    const requestNames = Object.getOwnPropertyNames(
      Object.getPrototypeOf(this)
    );
    this.cancelTokenHandlerObject = createCancelTokenHandler(
      $axios,
      requestNames
    );
  }

  // NEW
  async getSpecializations() {
    const data = await this.get('/doctor/specializations');
    return data;
  }

  async addDoctorToFavorite(params) {
    const url = `/doctor/favorite`;
    const response = await this.post(url, params);

    return response.data;
  }

  async deleteDoctorToFavorite(params) {
    const url = `/doctor/favorite`;
    const response = await this.delete(url, { data: params });

    return response.data;
  }

  async getDoctors(params) {
    const {
      data: { doctors }
    } = await this.get('/doctor/list', {
      params: {
        limit: params?.limit ?? 3000,
        ...params
      },
      paramsSerializer: (params) => qs.stringify(params),
      cancelToken: this.cancelTokenHandlerObject.getDoctors.handleRequestCancellation()
        .token
    });

    if (doctors) {
      return doctors.map((doctor) => {
        return {
          ...doctor,
          clinic: {
            id: doctor?.clinicId,
            name: doctor?.clinicName
          },
          id: doctor.doctorId
        };
      });
    }

    return [];
  }

  async getDoctorSchedule(doctorSpecializationId) {
    const {
      data: { slots }
    } = await this.get(`/doctor/${doctorSpecializationId}/schedule`);
    return slots;
  }

  async subscribeUserEmail(email) {
    const {
      data: { answer }
    } = await this.post(`/v3/subscription/email/${email}/add`);
    return answer;
  }

  addFileToConsultation(consultId, filename) {
    return this.post(`/consultation/${consultId}/attach-files`, {
      fileNames: [filename]
    });
  }

  async createConsultation(request) {
    const {
      data: { requestId }
    } = await this.post('/consultation/create', request);

    return requestId;
  }

  async createForDuty(params) {
    const { data } = await this.post('/consultation/createForDuty', params);

    return data;
  }

  async createForDutyQueue(id, params) {
    const { data } = await this.post(`/consultationQueue/${id}/create`, params);

    return data;
  }

  async getQueueById(id) {
    const { data } = await this.get(`/consultationQueue/ticket/${id}`);
    return data;
  }

  async getDoctor(id, byDoctorSpecializationId = false) {
    const { data } = await this.get(`/doctor/profile/${id}`, {
      params: {
        byDoctorSpecializationId
      }
    });
    return data;
  }

  async getClinic(id) {
    const { data } = await this.get(`/clinic/getById`, {
      params: {
        id
      }
    });
    return data;
  }

  async getClinicListByDoctors() {
    const {
      data: { clinics }
    } = await this.get(`/clinic/list-by-doctors`);
    return clinics;
  }

  async getClinicList(settings) {
    return await this.client.get('/clinic/list', settings);
  }

  async getClinicOffer(fileLink) {
    fileLink = fileLink.replace('/api', '');
    const { data } = await this.get(fileLink, {
      responseType: 'blob'
    });

    return data;
  }

  async getPaymentPromoCode(doctorSpecializationId, promocode) {
    try {
      const { data: paymentInfo } = await this.post(
        '/v1.1/payment/promo-code',
        null,
        {
          params: {
            doctorSpecializationId,
            promocode
          }
        }
      );

      return paymentInfo;
    } catch (e) {
      return false;
    }
  }

  async initPayture(consultationId, redirectUrl) {
    const {
      data: { url }
    } = await this.post('/payture/init', {
      consultationId,
      redirectUrl
    });

    return url;
  }

  async initPaymentPlantao(entityId, paidServiceType) {
    const {
      data: { url, orderId }
    } = await this.post('/payment/plantao/init', {
      entityId,
      paidServiceType
    });

    return { url, orderId };
  }

  async checkPayture(consultationId) {
    const {
      data: { url }
    } = await this.post('/payture/check', {
      consultationId,
      redirectUrl: `${window.location.origin}/events/${consultationId}?success-pay=true`
    });

    return url;
  }

  async initPaytureLabs(params) {
    const {
      data: { url }
    } = await this.post('/payture/init', {
      entityId: params.id,
      redirectUrl: params.url,
      paidServiceType: 14
    });

    return url;
  }

  async sendSmsCode(params) {
    const { data } = await this.post('/v3/auth/send-sms-code', params);
    return data;
  }

  async checkSmsCode(params) {
    const { data } = await this.post('/v3/auth/patient/login', params);
    return data;
  }

  async getPaymentType(doctorSpecializationId) {
    try {
      const { data } = await this.get('/v1.1/payment/request-price', {
        params: {
          doctorSpecializationId
        }
      });

      return data;
    } catch (e) {
      return false;
    }
  }

  // OLD METHODS
  async cancelConsultation(request) {
    await this.post('/consultation/cancel', request);
  }

  async getProtocolByConsultation(consultationId) {
    const { data } = await this.get('/protocol/getByConsultation', {
      params: {
        consultationId
      }
    });
    return data;
  }

  async getPdfByConsultation(consultationId, protocolType, timezone) {
    const { data } = await this.get(
      `/protocol/getPdfByConsultation/${consultationId}`,
      {
        params: {
          protocolType,
          timezone
        },
        paramsSerializer: (rawParams) => {
          const params = filterEmptyParameters(rawParams);
          return qs.stringify(params);
        },
        responseType: 'arraybuffer'
      }
    );
    return createBlobFromArrayBuffer(data, 'application/pdf');
  }

  /**
   * @return {Promise<Array.<PatientPolicy>>}
   */
  async getPolicyForPerson() {
    const { data } = await this.get('/policy/for-person');
    return data;
  }

  activatePolicy(body) {
    return this.put('/policy/activate', filterEmptyParameters(body));
  }

  async getClinicById(id) {
    const { data } = await this.get(`/clinic/getById`, {
      params: {
        id
      }
    });
    return data;
  }

  async getConsultationById(id) {
    const { data } = await this.get(`/consultation/${id}`);
    return data;
  }

  async addNewUserProfile(data) {
    await this.post('/patient/create', data);
  }

  async deleteProfile(id) {
    return await this.post('/patient/linked-profiles/unlink-child', {
      UnlinkPersonId: id
    });
  }

  async depersonalize() {
    return await this.put('/patient/depersonalize');
  }

  async getNotSignAgreements({ patientId, clinicId, consultationId, types }) {
    const { data } = await this.get('/agreement/getNotSignAgreements', {
      params: {
        patientId,
        clinicId,
        consultationId,
        types
      },
      withoutToast: true,
      paramsSerializer: (rawParams) => {
        const params = filterEmptyParameters(rawParams);
        return qs.stringify(params);
      }
    });
    return data;
  }

  async getSignedDocuments({ patientId, consultationId, types }) {
    const { data } = await this.get('/agreement/userAgreements', {
      params: {
        patientId,
        consultationId,
        types
      },
      paramsSerializer: (rawParams) => {
        const params = filterEmptyParameters(rawParams);
        return qs.stringify(params);
      }
    });
    return data;
  }

  async getReferral(id) {
    const { data } = await this.get('/referral', {
      params: { id },
      paramsSerializer: (rawParams) => {
        const params = filterEmptyParameters(rawParams);
        return qs.stringify(params);
      }
    });
    return data;
  }

  /* Monitoring */
  async getMonitoringId(id) {
    const { data } = await this.get(`/remote-monitoring`, {
      params: {
        id
      }
    });

    return data;
  }

  async getSeo({ type, id = null, slug = null }) {
    const { data } = await this.get(`/seo`, {
      params: {
        type,
        id,
        slug
      }
    });
    return data;
  }

  async getDutyTag(params) {
    const { data } = await this.get('/dutyQueue/findByTags', {
      params,
      paramsSerializer: (params) => qs.stringify(params)
    });
    return data;
  }

  async changeRemoteMonitoringTaskStatus(status, id) {
    await this.patch(`/remote-monitoring/task/${id}/status`, { status });
  }

  async getFamilyProfileToken(personId) {
    const {
      data: { data }
    } = await this.get('/v3/auth/family-profile', {
      params: { personId }
    });

    return data;
  }

  async getConsultationRequest(id) {
    const { data } = await this.get(`/consultation/${id}`);
    return data;
  }
}
