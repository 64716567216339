import createPersistedState from 'vuex-persistedstate';
export default ({ store }) => {
  createPersistedState({
    key: 'store',
    paths: [
      'modules.labs',
      'modules.auth.phone',
      'modules.auth.rawPhone',
      'modules.auth.nextAttemptTime',
      'modules.auth.isCodeInvalid',
      'modules.auth.error'
    ]
  })(store);
};
