export const state = () => ({
  modals: [],
  toasts: []
});

export const actions = {
  showModal({ commit }, modal) {
    commit('ADD_MODAL', modal);
  },
  closeModal({ state, commit }, modal) {
    const idx = state.modals.findIndex((m) => m.id === modal.id);
    if (idx !== -1) {
      commit('REMOVE_MODAL', idx);
    }
  },
  closeModalById({ state, commit }, modalId) {
    const idx = state.modals.findIndex((m) => m.id === modalId);
    if (idx !== -1) {
      commit('REMOVE_MODAL', idx);
    }
  },
  clearModals({ commit }) {
    commit('CLEAR_MODALS');
  },
  clearToasts({ commit }) {
    commit('CLEAR_TOASTS');
  },
  showToast({ commit }, toast) {
    commit('ADD_TOAST', toast);
  },
  hideToastById({ state, commit }, toastId) {
    const idx = state.toasts.findIndex((t) => t.id === toastId);
    if (idx !== -1) {
      commit('REMOVE_TOAST', idx);
    }
  }
};

export const mutations = {
  ADD_MODAL(state, modal) {
    state.modals.push(modal);
  },
  REMOVE_MODAL(state, modalIdx) {
    state.modals.splice(modalIdx, 1);
  },
  CLEAR_MODALS(state) {
    state.modals = [];
  },
  CLEAR_TOASTS(state) {
    state.toasts = [];
  },
  ADD_TOAST(state, modal) {
    state.toasts.push(modal);
  },
  REMOVE_TOAST(state, toastIdx) {
    state.toasts.splice(toastIdx, 1);
  }
};
