import SeoService from '@/services/seo/seo.service';

export default function(
  { $axios, $auth, $axiosClient, $hubClient, $config },
  inject
) {
  const client = $axiosClient.instance;

  const seoService = new SeoService(client, $hubClient, $config);

  inject('seoService', seoService);
}
