import { v4 as uuidv4 } from 'uuid';
import { FILE_CATEGORIES, FILE_TYPES } from './files.const';
import {
  createBlobFromArrayBuffer,
  convertBlobToBase64,
  convertBytesToMb
} from './files.helpers';
import FilesApi from './files.api';

class FilesService {
  constructor(app, client, $config) {
    this.app = app;
    this.api = new FilesApi(client, $config);
  }

  /**
   * @param {string} name
   * @return {Promise<Array.<ConsultationFile>>}
   */
  getFile(name) {
    return this.app.$authService.createChatImageUrl(name);
  }

  async getFilesConsultation(folder) {
    const { data } = await this.api.getFilesByFolderName(folder);
    return data;
  }

  /**
   * @param {string} filename
   * @param {string} mimeType
   * @return {Promise<Blob>}
   */
  async getFileByFilename({
    mimeType,
    name,
    previewName,
    extension,
    originName,
    totalSize
  }) {
    let previewBase64, fileBuffer, fullFileBuffer, blob, fullBlob, base64;
    if (mimeType.includes('image')) {
      fileBuffer = await this.api.getFileByFilename(previewName, mimeType);
      fullFileBuffer = await this.api.getFileByFilename(name, mimeType);
      blob = createBlobFromArrayBuffer(fileBuffer, mimeType);
      fullBlob = createBlobFromArrayBuffer(fullFileBuffer, mimeType);
      previewBase64 = await convertBlobToBase64(blob);
      base64 = this.getFile(name);
    } else {
      fileBuffer = await this.api.getFileByFilename(name, mimeType);
      blob = createBlobFromArrayBuffer(fileBuffer, mimeType);
      base64 = await convertBlobToBase64(blob);
    }
    return {
      title: originName,
      extension,
      size: convertBytesToMb(totalSize),
      file: fileBuffer,
      blob,
      fullBlob,
      src: base64,
      previewSrc: previewBase64
    };
  }

  /**
   * @param {FormData} file
   * @param {string} category
   * @param {string} mimeType
   * @param {number} fileTagId
   * @return {Promise<string>}
   */
  async saveFile(file, category, mimeType, fileTagId) {
    return await this.api.saveFile(file, category, mimeType, fileTagId);
  }

  async sendFiles(files) {
    const mappedFiles = files.map((file) => {
      const fileType = file.type.split('/')[0];
      const category =
        FILE_CATEGORIES.find((type) => type === fileType) || 'document';
      return {
        id: uuidv4(),
        category,
        mimeType: file.type,
        file
      };
    });

    const fileTag = FILE_TYPES.OTHER;
    const promises = mappedFiles.map((item) => {
      const { file, category, mimeType } = item;
      const formData = new FormData();
      formData.append('file', file);
      return this.saveFile(formData, category, mimeType, fileTag.id);
    });

    return await Promise.all(promises);
  }
}

export default FilesService;
