// import { branchStoreService } from '../../services/analysis/branchStoreService';
// import balloon from '../../components/analysis/map/Balloon';
// import { BIOMATERIAL_SERVICE } from '../../consts';
// import { cartStoreService } from '~/services/analysis/cartStoreService';

const getDefaultState = () => ({
  products: [],
  searchResult: [],
  searchHistory: [],
  branchList: [],
  // currentBranch: {},
  currentCity: {},
  currentPartnerId: '',
  currentRangeValue: [],
  isList: false,
  isMobileDevice: false,
  popularList: [],
  tagServices: [],
  currentBranchServices: [],

  selectedBranch: null,
  selectedServices: [],
  availablePartners: [],
  currentPriceRange: {
    minBasketCost: null,
    maxBasketCost: null
  },
  filtersPriceRange: {
    minBasketCost: null,
    maxBasketCost: null
  },
  filters: {
    city: null,
    branchIds: null,
    partnerIds: null,
    servicesIds: null
  },
  cart: [],
  promoCode: '',
  promoCodeStatus: {
    discountApplied: false,
    discountBasketCost: null,
    discountRejectionReason: null
  },
  orderEmail: '',
  orderPatient: null,
  firstAddToCart: false,
  referralId: null
});

const defaultPriceRange = {
  minBasketCost: null,
  maxBasketCost: null
};

export const state = getDefaultState;

export const getters = {
  cartPrice(state) {
    const cart = state.selectedServices ?? state.cart;

    if (!cart) {
      return 0;
    }

    return cart.reduce((acc, cV) => acc + (cV.cost ?? cV.minCost), 0);
  },
  serviceIds(state) {
    const ids = state.cart.map((item) => item.id);
    return ids;
  },
  isServiceInCart: (state) => (item) => {
    return state.cart.findIndex((service) => service.id === item.id) >= 0;
  },
  finalCart: (state) => {
    return state.selectedServices ?? state.cart;
  },
  isFinalPrice(state) {
    return (
      !!state?.selectedBranch ||
      state.filters.partnerIds?.length === 1 ||
      state.availablePartners?.length === 1
    );
  }
  // isEmptyBranch(state) {
  // eslint-disable-next-line no-unreachable-loop
  // for (const key in state.currentBranch) {
  // если тело цикла начнет выполняться - значит в объекте есть свойства
  // return false;
  // }
  // return true;
  // }
};

export const mutations = {
  // addAnalysis(state, analysis) {
  //   // state.products.push(analysis);
  //   state.products = analysis;
  // },
  // searchAnalysis(state, result) {
  //   state.searchResult = result;
  // },
  SET_REFERRAL_ID(state, id) {
    state.referralId = id;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  RESET_FILTERS(state) {
    state.filters.city = null;
    state.filters.partnerIds = null;
    state.currentPartnerId = '';
    state.currentPriceRange = defaultPriceRange;
    state.filtersPriceRange = defaultPriceRange;
  },
  SET_ORDER_PATIENT(state, branch) {
    state.orderPatient = branch;
  },
  SET_SEARCH_HISTORY(state, service) {
    state.searchHistory.push(service);
  },
  SET_FILTERS_PARAMS(state, filters) {
    state.filters = filters;
  },
  SET_BRANCH_LIST(state, branches) {
    state.branchList = branches;
  },
  SET_SELECTED_PATIENT(state, patient) {
    state.selectedPatient = patient;
  },
  SET_ORDER_EMAIL(state, email) {
    state.orderEmail = email;
  },
  SET_SELECTED_BRANCH(state, branch) {
    state.selectedBranch = branch;
  },
  SET_CURRENT_PRICE_RANGE(state, range) {
    state.currentPriceRange = range;
  },
  SET_FILTERS_PRICE_RANGE(state, range) {
    state.filtersPriceRange = range;
  },
  SET_SELECTED_SERVICES(state, services) {
    state.selectedServices = services;
  },
  SET_PROMO_CODE_STATUS(state, status) {
    state.promoCodeStatus = status;
  },
  REMOVE_PROMO_CODE(state, status) {
    state.promoCode = '';
    state.promoCodeStatus.discountBasketCost = null;
    state.promoCodeStatus.discountApplied = null;
    state.promoCodeStatus.discountRejectionReason = null;
  },
  SET_FIRST_ADD_TO_CART(state) {
    state.firstAddToCart = true;
  },
  SET_PROMO_CODE(state, promoCode) {
    state.promoCode = promoCode;
  },
  // setCurrentBranch(state, branch) {
  //   branchStoreService.setBranch(branch);
  //   state.currentBranch = branch;
  // },
  // setCurrentCity(state, city) {
  //   state.currentCity = city;
  // },
  // setCurrentPartnerId(state, partnerId) {
  //   state.currentPartnerId = partnerId;
  // },
  // setCurrentRangeValue(state, value) {
  //   state.currentRangeValue = value;
  // },
  // setIsFilter(state, condition) {
  //   state.isFilter = condition;
  // },
  // setIsList(state, condition) {
  //   state.isList = condition;
  // },
  // setIsMobile(state, condition) {
  //   state.isMobileDevice = condition;
  // },
  // setCart(state, item) {
  //   state.cart = item;
  // },
  // addToCart(state, service) {
  //   const bioMaterialService = {
  //     description: 'Забор биоматериала',
  //     id: '68a39a48-6e9c-4095-8e39-e5fdf02eb49f',
  //     maxCost: 200,
  //     minCost: 200,
  //     name: 'Забор биоматериала',
  //     tags: []
  //   };
  //   const cartStored = cartStoreService.get();
  //   const isContain = cartStored.filter((i) => i.id === service.id).length > 0;
  //   const isContainBio =
  //     cartStored.filter((i) => i.id === bioMaterialService.id).length > 0;
  //   cartStoreService.add(service, isContain, cartStored);
  //
  //   state.cart.push(service);
  //   if (!isContainBio) {
  //     cartStoreService.add(bioMaterialService, isContainBio, cartStored);
  //     state.cart.push(bioMaterialService);
  //   }
  // },
  // removeOfCart(state, service) {
  //   const cartStored = cartStoreService.get();
  //   const bioId = '68a39a48-6e9c-4095-8e39-e5fdf02eb49f';
  //   cartStoreService.del(service, cartStored);
  //
  //   state.cart = state.cart.filter((item) => item.id !== service.id);
  //   if (state.cart.length === 1 && state.cart[0].id === bioId) {
  //     state.cart = [];
  //     cartStoreService.setCart([]);
  //   }
  // },
  // savePopulars(state, populars) {
  //   state.popularList = populars;
  // },
  // saveBranchServices(state, services) {
  //   state.currentBranchServices = services;
  // },
  // saveTagServices(state, services) {
  //   state.tagServices = services;
  // },
  // clearCart(state) {
  //   state.cart = [];
  //   cartStoreService.setCart([]);
  // },
  SET_AVAILABLE_PARTNERS(state, partners) {
    state.availablePartners = partners;
  },
  ADD_SERVICE_TO_CART(state, service) {
    state.cart.push(service);
  },
  REMOVE_SERVICE_FROM_CART(state, service) {
    const idx = state.cart.findIndex((cartItem) => cartItem.id === service.id);
    if (idx !== -1) {
      state.cart.splice(idx, 1);
    }
  },
  REPLACE_SERVICES_FROM_CART(state, services) {
    state.cart = services;
  },
  CLEAR_CART(state) {
    state.cart = [];
  },
  CLEAR_PRICE_RANGE(state) {
    state.filters.priceRange = [];
  },
  CLEAR_PARTNER_IDS(state) {
    state.filters.partnerIds = null;
  }
};

export const actions = {
  async fetchCartServices({ commit, state }) {
    if (state.cart.length) {
      try {
        const data = await this.$labsService.costByBranch({
          BranchId: state.selectedBranch?.id,
          ServiceIds: state.cart.map((service) => service.id),
          PartnerIds: state.selectedBranch ? null : state.availablePartners,
          PromoCode: state.promoCode
        });

        const {
          basketCost,
          partnerId,
          basicItems,
          additionalItems,
          ...promocodeStatus
        } = data;

        if (basicItems && additionalItems) {
          commit('SET_SELECTED_SERVICES', [...basicItems, ...additionalItems]);
        }
        commit('SET_PROMO_CODE_STATUS', promocodeStatus);
      } catch (e) {
        commit('RESET_STATE');
      }
    }
  },
  async fetchCartServicesReferral({ commit, state, dispatch }, id) {
    if (!id) return;
    commit('RESET_STATE');
    try {
      if (id) {
        const { basicItems } = await this.$labsService.getServicesReferral(id);

        if (basicItems.length)
          for (const item of basicItems) dispatch('addServiceToCart', item);
        commit('SET_REFERRAL_ID', id);
      }
    } catch (e) {
      commit('RESET_STATE');
    }
  },
  async fetchCartServicesAnalysis({ commit, state, dispatch }, id) {
    if (!id) return;
    commit('RESET_STATE');
    try {
      if (id) {
        const { basicItems } = await this.$labsService.getServicesAnalysis(id);

        if (basicItems.length)
          for (const item of basicItems) dispatch('addServiceToCart', item);
        // commit('SET_REFERRAL_ID', id);
      }
    } catch (e) {
      commit('RESET_STATE');
    }
  },
  resetSelectedBranchServices({ commit }) {
    commit('SET_SELECTED_SERVICES', []);
  },
  setPromoCode({ commit }, code) {
    commit('SET_PROMO_CODE', code);
  },
  removePromoCode({ commit }) {
    commit('REMOVE_PROMO_CODE');
  },
  resetOrderData({ commit }) {
    commit('SET_ORDER_PATIENT', null);
    commit('SET_ORDER_EMAIL', '');
  },
  setOrderEmail({ commit }, email) {
    commit('SET_ORDER_EMAIL', email);
  },
  setOrderPatient({ commit }, patient) {
    commit('SET_ORDER_PATIENT', patient);
  },
  addServiceToCart({ state, commit }, service) {
    if (!state.firstAddToCart) {
      commit('SET_FIRST_ADD_TO_CART');
      this.$yandexMetrika.sendGoal('YM_analysis_AddToCart');
      this.$mailruMetrika.sendGoal('add_to_cart_analyzes');
      if (window.VK) {
        window.VK.Goal('add_to_cart');
      }
    }
    // if (!state.cart.length) {
    //   commit('ADD_SERVICE_TO_CART', BIOMATERIAL_SERVICE);
    // }

    commit('ADD_SERVICE_TO_CART', service);
  },
  setFiltersParams({ state, commit }, filters) {
    // TODO сделать сравнение обьектов
    commit('SET_FILTERS_PARAMS', filters);
  },
  setSelectedBranch({ state, commit }, branch) {
    commit('SET_SELECTED_BRANCH', branch);
  },
  setSearchHistory({ state, commit }, service) {
    commit('SET_SEARCH_HISTORY', service);
  },
  removeServiceFromCart({ state, commit }, service) {
    commit('REMOVE_SERVICE_FROM_CART', service);
    // const isLastService = !state.cart.length || state.cart.length === 1;
    //
    // if (isLastService) {
    //   commit('RESET_STATE');
    // } else {
    //   commit('REMOVE_SERVICE_FROM_CART', service);
    // }
  },
  replaceServicesFromCart({ commit }, services) {
    commit.REPLACE_SERVICES_FROM_CART(services);
  },
  clearCart({ commit }) {
    commit('RESET_STATE');
  },
  clearPartnerIds({ commit }) {
    commit('CLEAR_PARTNER_IDS');
  },
  // async fetchAnalysisTest({ state, commit }) {
  //   const {
  //     data: { data }
  //   } = await this.$axios.get('v3/laboratory/service/list');
  //   commit('addAnalysis', data.paginatedCollection);
  //   // return data.paginatedCollection;
  // },
  // async fetchPopulars({ state, commit }) {
  //   const {
  //     data: { data }
  //   } = await this.$axios.get('v3/laboratory/tag/list');
  //   commit('savePopulars', data.paginatedCollection);
  // },
  async fetchServicesList({ state, commit }, { name, tagIds, IsPopular }) {
    const services = await this.$labsService.getServicesList({
      name,
      tagIds,
      IsPopular,
      branchId: state.selectedBranch?.id
    });

    return services.paginatedCollection;
  },
  clearServices({ commit }) {
    commit('saveTagServices', []);
  },
  setFiltersPriceRange({ commit }, range) {
    commit('SET_FILTERS_PRICE_RANGE', range);
  },
  async fetchBranchesList(
    { state, commit },
    {
      AddressStr,
      Offset,
      ServiceIds,
      updatePriceRange,
      topLeftCornerX,
      topLeftCornerY,
      bottomRightCornerX,
      bottomRightCornerY
    }
  ) {
    if (updatePriceRange) {
      commit('SET_FILTERS_PRICE_RANGE', {
        minBasketCost: null,
        maxBasketCost: null
      });
    }

    const { city, partnerIds } = state.filters;
    const { minBasketCost, maxBasketCost } = state.filtersPriceRange;

    const branches = await this.$labsService.getBranchesList({
      CityId: city?.id,
      AddressStr,
      Offset,
      ServiceIds,
      PartnerIds: partnerIds,
      MinCost: minBasketCost,
      MaxCost: maxBasketCost,
      topLeftCornerX,
      topLeftCornerY,
      bottomRightCornerX,
      bottomRightCornerY
    });

    if (updatePriceRange) {
      commit('SET_CURRENT_PRICE_RANGE', {
        minBasketCost: branches.minBasketCost,
        maxBasketCost: branches.maxBasketCost
      });
    }

    commit('SET_AVAILABLE_PARTNERS', branches.partnerIds);
    commit('SET_BRANCH_LIST', branches.paginatedCollection);
    return branches.paginatedCollection;
  },
  fetchServiceById({ state, commit }, id) {
    return this.$labsService.getServiceById(id);
  },
  fetchTagById({ state, commit }, id) {
    return this.$labsService.getTagById(id);
  },
  fetchPartnerList() {
    return this.$labsService.getPartnersList();
  },
  fetchCitiesList() {
    return this.$labsService.getCitiesList();
  },
  fetchLabsCitiesList() {
    return this.$labsService.getLabsCitiesList();
  },
  createOrder({ state, commit }, params) {
    return this.$labsService.createOrder(params);
  },
  resetState({ commit }) {
    commit('RESET_STATE');
  },
  resetFilters({ commit }) {
    commit('RESET_FILTERS');
  }
  // async paytureInit({ state, commit }) {
  //   const params = {
  //     paidServiceType: 14,
  //     entityInfo: 'string',
  //     redirectUrl: 'string',
  //     jsonParams: 'string',
  //     email: 'string',
  //     entityId: '110eb561-2bf4-4b0c-9e95-19614445f988'
  //   };
  //   this.$axios.setToken('98aecb71-f888-49de-a499-dcb17009d633', 'Bearer');
  //
  //   const {
  //     data: { data }
  //   } = await this.$axios.post('payture/init', params);
  //
  //   this.$axios.setToken(false);
  //
  //   return data;
  // }
};

export default {
  state,
  actions,
  mutations,
  getters
};
