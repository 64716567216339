import MomsSchoolService from '@/services/moms-school/moms-school.service';

export default function(
  { $axios, $auth, $axiosClient, $hubClient, $config },
  inject
) {
  const client = $axiosClient.instance;

  const momsSchoolService = new MomsSchoolService(client, $hubClient, $config);

  inject('momsSchoolService', momsSchoolService);
}
