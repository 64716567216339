<template>
  <div>
    <the-call v-if="$auth.loggedIn" />
  </div>
</template>

<script>
export default {
  name: 'SystemModals',
  components: {
    TheCall: () => import('@/components/global/TheCall')
  }
};
</script>
