import { differenceInYears, formatDuration } from '@evd3v/date-fns';

export const getProfileFullName = ({ lastName, firstName, middleName }) => {
  return [lastName, firstName, middleName].filter((v) => v).join(' ');
};

export const getNameInitial = (user, isOnlyInitial = false) => {
  const { firstName, lastName, middleName } = user;
  const initalFirst = firstName ? firstName.slice(0, 1) : '';
  const initalMiddle = middleName ? middleName.slice(0, 1) : '';

  if (isOnlyInitial) return `${initalFirst}${initalMiddle}`;
  else return `${lastName} ${initalFirst}.${initalMiddle}.`;
};

export const getAgeTitle = ({ birthDate }) => {
  if (!birthDate) return '';
  const age = differenceInYears(new Date(), new Date(birthDate));
  if (!age) return 'common.general-words.infant';

  return formatDuration({ years: age });
};
