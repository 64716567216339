import ConsultationReviewApi from './consultation-review.api';

class ConsultationReviewService {
  constructor(client, $config) {
    this.api = new ConsultationReviewApi(client, $config);
  }

  createReview(payload) {
    return this.api.createReview(payload);
  }

  editReview(payload) {
    return this.api.editReview(payload);
  }
}

export default ConsultationReviewService;
