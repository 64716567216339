export const consultationStatus = {
  NEW: {
    id: 0,
    name: 'new',
    text: 'consts.consultation-status.new'
  },
  IN_WORK: {
    id: 1,
    name: 'in-work',
    text: 'consts.consultation-status.in-work'
  },
  CLOSED: {
    id: 2,
    name: 'closed',
    text: 'consts.consultation-status.closed'
  },
  ON_COUNCIL: {
    id: 3,
    name: 'on-council',
    text: 'consts.consultation-status.on-council'
  },
  WAIT_FOR_PAY: {
    id: 4,
    name: 'wait-for-pay',
    text: 'consts.consultation-status.wait-for-pay'
  },
  CANCELED: {
    id: 6,
    name: 'canceled',
    text: 'consts.consultation-status.canceled'
  },
  NOT_PAID: {
    id: 7,
    name: 'not-paid',
    text: 'consts.consultation-status.not-paid'
  },
  CANCELEDPATIENT: {
    id: 8,
    name: 'canceled',
    text: 'consts.consultation-status.canceled-patient'
  },
  CANCELEDDOCTOR: {
    id: 9,
    name: 'canceled',
    text: 'consts.consultation-status.canceled-doctor'
  }
};

export const communicationMethodConsultation = {
  0: 'consts.communication-methods.text',
  1: 'consts.communication-methods.audio',
  2: 'consts.communication-methods.video'
};

export const communicationMethods = {
  TEXT: {
    id: 0,
    name: 'consts.communication-methods.text',
    icon: 'call/chat-bold',
    newIcon: 'content/chat'
  },
  AUDIO: {
    id: 1,
    name: 'consts.communication-methods.audio',
    icon: 'call/phone-bold',
    newIcon: 'phone'
  },
  VIDEO: {
    id: 2,
    name: 'consts.communication-methods.video',
    icon: 'call/video-bold',
    newIcon: 'video'
  }
};

export const shortcutDays = 'consts.shortcut-days';

export const fileCategories = ['image', 'audio', 'video', 'document'];

export const fileTypes = {
  ANALYSIS: {
    id: 1,
    title: 'consts.file-types.analysis'
  },
  RESEARCH: {
    id: 2,
    title: 'consts.file-types.research'
  },
  INSPECTION: {
    id: 3,
    title: 'consts.file-types.inspection'
  },
  OTHER: {
    id: 4,
    title: 'consts.file-types.other'
  }
};

export const months = 'consts.months';

export const DOMAINS_SETTINGS = {
  default: {
    theme: 'default',
    logo: 'doctis-logo',
    title: 'doctis.ru',
    showRegionFilter: true,
    defaultRegionId: ''
  }
};

export const LOCALES = {
  RU: 'ru',
  EN: 'en'
};

export const TYPE_OFFERS = {
  offer: 'Offer',
  personalDataAgreement: 'PersonalDataAgreement',
  medicalInterventionAgreement: 'MedicalInterventionAgreement'
};

export const STATE_EVENT = {
  coming: 0,
  current: 1,
  completed: 2
};

export const TYPE_EVENT = {
  consultation: 0,
  queue: 1,
  monitoring: 2,
  medicineReferral: 3,
  analysisReferral: 7,
  consultationReferral: 8,
  analysis: 10
};

export const seoEntityType = {
  SPECIALIZATION: 1,
  DOCTOR: 3,
  LABS_CATEGORY: 4,
  LABS_ANALYSIS: 5
};

export const protocolType = {
  COOMMERCIAL_PATIENT: 0,
  COOMMERCIAL_DOCTOR: 1,
  STATE_DOCTOR: 2
};
export const yandexMetrikaId = 90067095;

export const vkMetrikaId = 'VK-RTRG-1198370-e0URn';

export const USER_TYPE = {
  FREE: 0,
  COMMERCIAL: 1,
  DOCTOR: 2
};

export const CONSULTATION_TAGS = [
  {
    id: 1,
    title: 'consts.consultation-tags.red',
    color: '#E14D4D'
  }, // Красное
  {
    id: 2,
    title: 'consts.consultation-tags.yellow',
    color: '#FFD056'
  }, // Жетлое
  {
    id: 3,
    title: 'consts.consultation-tags.green',
    color: '#A8E19E'
  }, // Зеленый
  {
    id: 4,
    title: 'consts.consultation-tags.gray',
    color: '#BABABA'
  } // Серый
];

export const BIOMATERIAL_SERVICE = {
  description: 'consts.biomaterial.title',
  id: '68a39a48-6e9c-4095-8e39-e5fdf02eb49f',
  maxCost: 200,
  minCost: 200,
  name: 'consts.biomaterial.name',
  tags: []
};

export const XVCProjectEnum = {
  DOCTIS: 0,
  RT_HEALTH: 1,
  GOSTELEMED: 2,
  TELEMED52: 3,
  FOOD_FESSIONAL: 4
};

export const TASK_STATUSES = {
  NEW: 0,
  CANCELED: 1,
  FINISHED: 2,
  CLOSED: 3,
  PROCESS: 4,
  NOTRESPONSE: 5,
  SUSPENDED: 6
};

export const REMOTE_MONITORING_STATUS = {
  ACTIVE: 0, // Активе
  CONCLUDED: 1, // Завершен
  REFUSED: 2, // Отказ от мониторинга
  CANCELED: 3, // Отменен
  SUSPENDED: 4 // Приостановлен
};

export const RM_NDICATOR_TYPES = [
  {
    id: 0,
    title: 'consts.indicator-types.list',
    component: 'IndicatorSelect'
  },
  {
    id: 1,
    title: 'consts.indicator-types.enter-num',
    component: 'IndicatorNumber'
  },
  {
    id: 2,
    title: 'consts.indicator-types.enter-text',
    component: 'IndicatorText'
  },
  {
    id: 3,
    title: 'consts.indicator-types.yes-or-no',
    component: 'IndicatorBoolean'
  },
  {
    id: 4,
    title: 'consts.indicator-types.chart',
    component: 'IndicatorGraph'
  },
  {
    id: 5,
    title: 'consts.indicator-types.file',
    component: 'IndicatorFile'
  },
  {
    id: 6,
    title: 'consts.indicator-types.average',
    component: 'IndicatorNumberArray'
  }
];

export const INDICATOR_COLLECTION_TYPE = {
  APPLICATION: 0,
  CTG: 1,
  TONOMETR: 2,
  CALL_CENTER: 3,
  ROBOT: 4,
  GLUCOMETER: 5
};

export const INDICATOR_CANONICAL_NAMES = {
  CTGTOKO: 1,
  CTGFHR: 2,
  CTGCHILDMOVEMENTS: 3,
  CTGSOUND: 4,
  CTGTIME: 5,
  TONOMETRDISTAL: 6,
  TONOMETRSYSTOLIC: 7,
  TONOMETRPULSE: 8,
  GLUCOSELEVEL: 9
};

export const DECLAMINATIONS = {
  YEARS: 'consts.years',
  CONSULTATIONS: 'consts.consultations',
  REVIEWS: 'consts.reviews'
};

export const APPLICATION_LINK =
  'https://3505990.redirect.appmetrica.yandex.com/?appmetrica_tracking_id=604594331310883378';

export const PATH_TO_LICENSE =
  'https://fileserver.doctis.ru/content/license-dmo.pdf';

export const PATH_TO_REGISTRY = 'https://reestr.digital.gov.ru/reestr/501260/';

export const PATH_TO_PERSONAL_DATA =
  'https://static.doctis.ru/doctis/personal_data_processing_policy_dmo.pdf';

export const PATH_TO_LOGO = {
  RU: '/images/logo/logo.webp',
  EN: '/images/logo/logo_en.webp'
};
