export default function({ $config, i18n }) {
  if (process.client) {
    localStorage.setItem('locale', $config.APP_LOCALE);
  }

  i18n.locale = $config.APP_LOCALE;
  i18n.separateToArray = (string, index) => {
    const keys = string.split('.');
    let localeMessage = { ...i18n.messages[i18n.locale] };

    for (let i = 0; i < keys.length; i++) {
      localeMessage = localeMessage[keys[i]];
      if (!localeMessage) return [string];
    }

    const result = localeMessage.split('|');

    if (Array.isArray(index)) {
      const keysResult = [];
      index.forEach((idx) => {
        keysResult.push(result[idx]);
      });
      return keysResult;
    }
    if (Number.isInteger(index)) return result[index];

    return result;
  };
}
