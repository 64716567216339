class YandexMetrika {
  constructor(YM_ID) {
    this.YM_ID = YM_ID;
    if (window) {
      if (window && window.ym) {
        window.ym(YM_ID, 'init');
      }
    }
  }

  sendGoal(target, id, goalParams) {
    if (!id) {
      id = this.YM_ID;
    }
    if (window && window.ym) {
      window.ym(id, 'reachGoal', target, goalParams);
    }
  }
}

export default YandexMetrika;
