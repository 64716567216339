<template>
  <div class="container h-full">
    <div
      v-if="error.isAxiosError && errorCode"
      class="flex items-center justify-center"
    >
      <div
        class="flex flex-col justify-center items-center w-full w-80 bg-v-ui-root-monochrome-0 p-8 rounded-2xl"
      >
        <div class="text-v-ui-text-monochrome font-bold text-xl">
          {{ $t('domains.domains.layouts.error.title') }}
        </div>
        <div
          class="flex items-center justify-center bg-v-ui-root-monochrome-5 mt-4 px-2 h-8 w-full rounded"
        >
          <span class="text-sm font-medium"
            >{{ $t('domains.domains.layouts.error.code') }}:
            {{ errorCode }}</span
          >
        </div>
        <div
          class="text-base font-medium mt-4 text-v-ui-text-monochrome-55 text-center"
        >
          {{ $t('domains.domains.layouts.error.tg') }}
        </div>
        <v-button
          :label="$t('common.buttons.copy-and-send')"
          :right-icon="{ name: 'brand/telegram' }"
          @click="sendErrorToTelegram"
        />
      </div>
    </div>

    <!-- DEFAULT_ERROR -->
    <div
      v-else
      class="max-w-[500px] text-center mx-auto h-full pb-[155px] pt-[173px]"
    >
      <div class="text-8xl font-bold md:mb-24 mb-9 text-v-ui-text-main">
        404
      </div>

      <div class="md:title-32-bold-700 title-24-bold-700 mb-4">
        {{ $t('domains.domains.layouts.error.doctors.page-not-found') }}
      </div>
      <div
        class="md:title-20-medium-500 title-16-medium-500 text-v-ui-text-monochrome-55 md:mb-10 mb-4"
      >
        {{ $t('domains.domains.layouts.error.doctors.check-address-page') }}
      </div>

      <v-button
        :label="titleButton"
        :size="isMobile ? 's' : 'l'"
        @click="onBack"
      />
    </div>
    <!-- ./DEFAULT_ERROR -->
  </div>
</template>

<script>
import { VButton } from '@doctis.front/doctis.designsystem';
import { mapState } from 'vuex';
import TelegramIconSvg from '@/assets/icons/telegram.svg?inline';

export default {
  name: 'Error',
  // eslint-disable-next-line vue/no-unused-components
  components: { VButton, TelegramIconSvg },
  layout: 'main',
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      jsonError: null,
      isBackMain: false
    };
  },
  computed: {
    ...mapState(['isMobile']),
    errorCode() {
      return (
        this.error?.isAxiosError && this.error?.response?.data?.data?.errorId
      );
    },
    titleButton() {
      return this.isBackMain
        ? this.$t('domains.domains.layouts.error.to-main-page')
        : this.$t('domains.domains.layouts.error.doctors.chose-doctors');
    }
  },
  mounted() {
    console.log(this.error);
    this.isBackMain = !this.$route.name;
  },
  methods: {
    async sendErrorToTelegram() {
      await navigator.clipboard.writeText(this.errorCode);
      window.open('https://t.me/Doctis_error_bot', '_blank').focus();
    },
    onBack() {
      if (this.isBackMain) return this.$router.push('/');
      this.$router.push('/doctors');
    }
  }
};
</script>
