import { isBefore } from '@evd3v/date-fns';
import { POLICY_STATUS } from './policy.const';
import PolicyApi from '@/services/policy/policy.api';
import { filterEmptyParameters } from '@/helpers';

class PolicyService {
  constructor(client, $config, i18n) {
    this.api = new PolicyApi(client, $config);
    this.$t = i18n.t.bind(i18n);
  }

  async getPolicy() {
    const { data } = await this.api.getPolicy();
    return this.getActivePolicy(data);
  }

  getActivePolicy(policy) {
    return policy.map((i) => {
      const startDate = new Date();
      const endDate = new Date(i.endDate);

      return {
        ...i,
        active:
          isBefore(startDate, endDate) && i.status === POLICY_STATUS.ACTIVE
      };
    });
  }

  async activatePolicy(body) {
    return await this.api.activatePolicy(filterEmptyParameters(body));
  }
}

export default PolicyService;
