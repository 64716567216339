import AuthService from '@/services/auth/auth.service';

export default function(
  { $axiosClient, $config, $auth, store, route, $recaptcha, app },
  inject
) {
  const client = $axiosClient.instance;

  const authService = new AuthService(
    client,
    $config,
    $auth,
    store,
    $recaptcha,
    app
  );
  inject('authService', authService);
}
