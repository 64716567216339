import DirectionsApi from './directions.api';

export const DIRECTION_STATUS = {
  OVERDUE: 'Overdue',
  PROVIDED: 'Provided',
  DECLINED: 'Declined',
  NEW: 'New'
};

export const MEDICINE_INFO_OPTIONS = {
  name: 'Фармацевтические вещества и активные фармацевтические ингредиенты',
  dose: 'Разовая доза',
  takingMethod: 'Способ приема',
  takingFrequency: 'Кратность приема',
  takingTime: 'Длительность курса',
  courseDuration: 'Время приема'
};

export const referralDtoToModel = ({ medicines, ...referralInfo }) => ({
  ...referralInfo,
  medicines: medicines.map(
    ({
      internationalNonproprietaryName,
      medicinePrescription: {
        dose,
        takingMethod,
        takingFrequency,
        takingTime,
        courseDuration
      },
      ...medicine
    }) => ({
      ...medicine,
      medicinePrescription: {
        name: internationalNonproprietaryName,
        dose,
        takingMethod,
        takingFrequency,
        takingTime,
        courseDuration
      }
    })
  )
});

class DirectionsService {
  constructor(client, $config) {
    this.api = new DirectionsApi(client, $config);
  }

  async getReferralById(referralId) {
    const params = {
      referralId
    };

    const {
      data: { data }
    } = await this.api.referralMedicineInfo(params);

    return referralDtoToModel(data);
  }

  async referralAccept(params) {
    const {
      data: { data }
    } = await this.api.referralAccept(params);

    return data;
  }

  async referralDecline(params) {
    const {
      data: { data }
    } = await this.api.referralDecline(params);

    return data;
  }
}

export default DirectionsService;
