import { Api } from '~/api';

class SeoApi extends Api {
  constructor(client, $config) {
    const baseUrl = $config.APP_NEW_API;
    super(client, baseUrl);

    this.baseURL = baseUrl;
  }

  getSpecialization(id) {
    return this.get(`/seo/specialization?specializationId=${id}`);
  }
}

export default SeoApi;
