import NotificationsService from '@/services/notifications/notifications.service';

export default function(
  { $axios, $auth, $axiosClient, $hubClient, $config },
  inject
) {
  const client = $axiosClient.instance;

  const notificationsService = new NotificationsService(
    client,
    $hubClient,
    $config
  );

  inject('notificationsService', notificationsService);
}
