<template>
  <div class="overflow-auto w-full">
    <div
      :key="groupsMessages.length"
      class="flex items-end flex-col w-full min-h-full justify-end"
    >
      <div
        v-for="(messages, key) of listChats"
        :id="`messages-group-${key}`"
        :key="key"
        class="messages-group w-full"
      >
        <component
          :is="`SupportChatMessage${getMessageType(message)}`"
          v-for="message of messages"
          :key="message.id"
          :message="message"
          @setMessage="setMessage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { MESSAGE_TYPE } from '@/services/support-chat/support.const';
import SupportChatMessageFile from '@/components/domains/support/ui/chat/messages/SupportChatMessageFile';
import SupportChatMessageImage from '@/components/domains/support/ui/chat/messages/SupportChatMessageImage';
import SupportChatMessageText from '@/components/domains/support/ui/chat/messages/SupportChatMessageText';
import SupportChatMessageSystem from '@/components/domains/support/ui/chat/messages/SupportChatMessageSystem';
import SupportChatMessageTimestamp from '@/components/domains/support/ui/chat/messages/SupportChatMessageTimestamp';
import SupportChatMessageCloseAppeal from '@/components/domains/support/ui/chat/messages/SupportChatMessageCloseAppeal';

export default {
  name: 'SupportChatMessages',
  components: {
    SupportChatMessageFile,
    SupportChatMessageImage,
    SupportChatMessageText,
    SupportChatMessageSystem,
    SupportChatMessageTimestamp,
    SupportChatMessageCloseAppeal
  },
  props: {
    groupsMessages: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    listChats() {
      return this.groupsMessages;
    }
  },
  methods: {
    setMessage(message) {
      this.$emit('setMessage', message);
    },
    getMessageType(message) {
      if (message.isLocalFile && message.messageType === MESSAGE_TYPE.FILE) {
        return this.$supportChatService.getExt(JSON.parse(message.payload));
      } else return message.messageType;
    }
  }
};
</script>
