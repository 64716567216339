import MomsSchoolApi from './moms-school.api';

class MomsSchoolService {
  constructor(client, hub, $config) {
    this.api = new MomsSchoolApi(client, $config);
    this.hub = hub;
  }

  async getEvents() {
    const { data } = await this.api.list();
    return data;
  }

  async getEvent(id) {
    const { data } = await this.api.getEvent(id);
    return data;
  }

  async checkAccess() {
    const { data } = await this.api.checkAccess();
    return data;
  }

  async register(id) {
    const { data } = await this.api.register(id);
    return data;
  }

  async deregister(id) {
    const { data } = await this.api.deregister(id);
    return data;
  }
}

export default MomsSchoolService;
