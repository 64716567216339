import {
  getPatientInfo,
  getConsultationInfo,
  getProgressInfo
} from '../referral-analysis/referral-analysis.helpers';
import { getBreadcrumbs } from './referral-consultations.helpers';
import ReferralConsultationsApi from './referral-consultations.api';

class ReferralConsultationsService {
  constructor(client, $config) {
    this.api = new ReferralConsultationsApi(client, $config);
  }

  async getReferral(id, breadcrumbsTitle) {
    const { data } = await this.api.getReferral(id);

    return {
      ...data,
      patient: getPatientInfo(data.patient),
      ...getConsultationInfo(data),
      breadcrumbs: getBreadcrumbs(data.status, breadcrumbsTitle),
      progress: getProgressInfo(data)
    };
  }

  async declineRecommendation({ id }) {
    await this.api.declineRecommendation(id);
  }
}

export default ReferralConsultationsService;
