import { Api } from '~/api';

class MedicalQuestionnaireApi extends Api {
  constructor(client, $config) {
    const baseUrl = $config.APP_NEW_API + '/patient';
    super(client, baseUrl);
    this.baseURL = baseUrl;
    this.anketaDefault = {
      patientId: 0,
      height: 0,
      weight: 0,
      chronicDiseases: '',
      allergies: '',
      permanentMedications: '',
      bloodType: '',
      rhFactor: '',
      surgeries: '',
      medicalQuestionnaireForWomenResponse: {
        menstrualCycleRegularity: '',
        menstrualCycleDuration: 0,
        isPregnant: null,
        currentPregnancyInWeeks: '',
        pregnancyResult: '',
        pregnancyResultWeek: '',
        hadPregnanciesInThePast: null,
        hadAbortions: null,
        lastAbortionReason: ''
      }
    };
  }

  async getMedicalQuestionnaire(id) {
    const {
      data: { medicalQuestionnaire: anketa, profileData: patient }
    } = await this.get(`/medical-questionnaire?patientId=${id}`);
    return {
      anketa: anketa || this.anketaDefault,
      patient
    };
  }

  async addMedicalQuestionnaire(payload) {
    return await this.post('/medical-questionnaire', payload);
  }

  async updateMedicalQuestionnaire(payload) {
    return await this.put('/medical-questionnaire', payload);
  }
}

export default MedicalQuestionnaireApi;
