export const state = () => ({
  profile: null,
  strategy: null,
  familyProfiles: []
});

export const mutations = {
  SET_PROFILE(state, profile) {
    state.profile = profile;
  },
  SET_FAMILY_PROFILES(state, profiles) {
    state.familyProfiles = profiles;
  }
};

export const actions = {
  async fetchProfile({ commit }) {
    const profile = await this.$patientService.getProfile();
    commit('SET_PROFILE', profile);
  },

  async fetchFamilyProfiles({ commit }) {
    const profiles = await this.$patientService.getFamilyProfiles();
    commit('SET_FAMILY_PROFILES', profiles);
  }
};
