import { format } from '@evd3v/date-fns';
import { convertBytesToMb } from '../files/files.helpers';

export const getResultFiles = (files, isDelete = false) => {
  return files.map(({ createdAt, fileExtension, fileSize, name, id }) => {
    const date = new Date(createdAt);
    return {
      id,
      type: 'File',
      name: `${name}.${fileExtension}`,
      extension: fileExtension.toUpperCase(),
      size: convertBytesToMb(fileSize),
      date: `${format(date, 'yy.MM.dd')} в ${format(date, 'HH:mm')}`,
      rightIcon: isDelete ? 'm/delet' : null
    };
  });
};
