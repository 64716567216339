<template>
  <div ref="dropdown">
    <v-button
      :label="label"
      :type="btnType"
      rounded
      :right-icon="{
        name: isOpened ? 's/arrow_up_chevron' : 's/arrow_down_chevron',
        handler: toggleMenu
      }"
      :class="buttonClass"
      @click="toggleMenu"
    />
    <ul
      v-show="isOpened"
      class="lg:bg-v-ui-root-monochrome-0 lg:absolute lg:shadow-lg lg:px-4 lg:whitespace-nowrap lg:top-11 lg:left-0 lg:rounded-lg"
    >
      <li v-for="(item, idx) in items" :key="idx" @click="closeMenu">
        <slot name="item" :item="item"></slot>
      </li>
    </ul>
  </div>
</template>

<script>
import { VButton } from '@doctis.front/doctis.designsystem';
export default {
  name: 'UDropdown',
  components: { VButton },
  props: {
    label: {
      type: String,
      default: ''
    },
    btnType: {
      type: String,
      default: 'light'
    },
    items: {
      type: Array,
      required: true
    },
    buttonClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isOpened: false
    };
  },
  mounted() {
    window.addEventListener('click', this.handleOutsideClickMenu);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleOutsideClickMenu);
  },
  methods: {
    handleOutsideClickMenu(e) {
      if (this.isOpened) {
        const isClickInsideMenu = this.$refs.dropdown.contains(e.target);
        if (!isClickInsideMenu) {
          this.closeMenu();
        }
      }
    },
    closeMenu() {
      this.isOpened = false;
    },
    openMenu() {
      this.isOpened = true;
    },
    toggleMenu() {
      return this.isOpened ? this.closeMenu() : this.openMenu();
    }
  }
};
</script>

<style scoped></style>
