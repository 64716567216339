import { Api } from '@/api/index';

export default class landingApi extends Api {
  constructor($axios, $config) {
    const baseUrl = $config.APP_LANDING_API;

    super($axios, baseUrl);
  }

  async confirmSmsCode(data) {
    return await this.post('/confirm_sms_code/', data, {
      withCredentials: false
    });
  }

  async sendSmsCode(data) {
    return await this.post('/send_sms_code/', data, {
      withCredentials: false
    });
  }

  async sendPassport(data) {
    return await this.patch('/delivery_devise/passport_data_update/', data, {
      withCredentials: false
    });
  }

  async getCities(data) {
    return await this.get(
      '/delivery_devise/list_exception_cities/',
      {
        params: data
      },
      {
        withCredentials: false
      }
    );
  }

  async getPriceDelivery(data) {
    return await this.get(
      '/delivery_devise/price_delivery/',
      {
        params: data
      },
      {
        withCredentials: false
      }
    );
  }

  async orderCreate(data) {
    return await this.post('/order_create/', data, {
      withCredentials: false
    });
  }

  async useCoupon(data) {
    return await this.post('/use_coupon/', data, {
      withCredentials: false
    });
  }

  async createPayture(data) {
    return await this.post('/session_create/pautyre/', data, {
      withCredentials: false
    });
  }

  async createRemander(data) {
    return await this.post('/reminder/create_reminder_pregnancy/', data, {
      withCredentials: false
    });
  }

  async orderUpdate(data) {
    return await this.patch('/order_update/', data, {
      withCredentials: false
    });
  }
}
