import Vue from 'vue';
import StoriesPlugin from '@doctis.front/library.vuecomponent.storiesclient';
import '@doctis.front/library.vuecomponent.storiesclient/dist/lib.css';

export default function({ $axiosClient, $config, $uiService, $authService }) {
  const apiClient = {
    client: $axiosClient.instance,
    options: {
      baseUrl: $config.APP_NEW_API
    }
  };

  Vue.use(StoriesPlugin, {
    apiClient,
    locale: $config.APP_LOCALE,
    uiService: $uiService,
    authService: $authService
  });
}
