import * as Emitter from 'event-emitter';
import DispatcherApi from './dispatcher.api';

const STATUSES = {
  ERROR: 'error',
  SUCCESS: 'success'
};

const MESSAGE_TYPE = 'dispatcher.response';

export default class DispatcherClient {
  constructor($client, $hub, $store, $config) {
    const baseUrl = $config.APP_NEW_API + '/v2';

    this.client = new DispatcherApi($client, baseUrl);
    this.$store = $store;
    this.hub = $hub;

    this.emitter = new Emitter();
  }

  static SERVER_EXCEED_TIMEOUT = 145;

  init() {
    this.hub.on(MESSAGE_TYPE, this.onMessage.bind(this));
  }

  onMessage(response) {
    const { id } = response;

    this.emitter.emit(id, response);
  }

  waitForResponse(requestId) {
    return new Promise((resolve, reject) => {
      const exceedTimeout = setTimeout(() => {
        reject(new Error(`Timeout exceed, requestId: ${requestId} `));
        this.emitter.off(requestId, reject);
      }, DispatcherClient.SERVER_EXCEED_TIMEOUT * 1000);

      this.emitter.once(requestId, (response) => {
        try {
          const { id, status, message, queryResult } = response;

          if (status === STATUSES.ERROR) {
            const errorMessage = `${id}: ${message}`;

            console.error(errorMessage);
            reject(new Error(errorMessage));
            return;
          }

          clearTimeout(exceedTimeout);
          resolve(queryResult);
        } catch (e) {
          throw new Error('Something went wrong when response was parsed');
        }
      });
    });
  }

  async post(url, body = {}, options = {}) {
    if (!url) {
      throw new Error('url cannot be empty');
    }

    let payload = body;

    payload = '';

    const { data } = await this.client.post(url, payload, options);

    if (data.id) {
      return this.waitForResponse(data.id);
    } else {
      return data;
    }
  }

  get(url, ...args) {
    return this.client.get(url, ...args);
  }
}
