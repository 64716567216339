<template>
  <div class="py-1 px-4">
    <v-chat-message type="System" text="Обращение закрыто" />
  </div>
</template>

<script>
import { VChatMessage } from '@doctis.front/doctis.designsystem';
export default {
  name: 'SupportChatMessageCloseAppeal',
  components: { VChatMessage }
};
</script>
