<template>
  <div class="flex items-end pl-4 pr-2.5 my-2 relative" :class="[messageClass]">
    <v-chat-message
      v-if="isPhotoNotLoaded && !message.isLocalFile"
      :message-id="message.id"
      :message-url="message.previewSrc"
      :is-incoming="message.incoming"
      :type="message.messageType"
      :time-stamp="createdAtFormatted"
      :is-preview="true"
      progress-color="text-v-ui-text-monochrome-0"
      :progress-value="progressValue"
      :is-error="message.isError"
      :is-sending="message.isSending"
      :is-single="true"
      :is-loading-error="isLoadingError"
      :is-loading="isProgressVisible"
      :message-options="messageOptions"
      :was-read="message.wasRead"
      :error-text="$t('domains.consultation.downloading-error')"
      @onDownload="onSelectOption({ key: 'Download-to-device' })"
      @onMenuSelect="onSelectOption"
      @select="onSelectOption(messageKey)"
    />
    <v-chat-message
      v-if="!isPreview || !message.isPreview"
      :message-id="message.id"
      :message-url="imageUrl"
      :is-incoming="message.incoming"
      :class="isPhotoNotLoaded ? 'hidden' : ''"
      :type="message.messageType"
      :time-stamp="createdAtFormatted"
      :is-preview="message.isPreview && isPreview"
      :is-error="message.isError"
      :is-sending="message.isSending"
      progress-color="text-v-ui-text-monochrome-0"
      :is-single="true"
      :is-loading-error="isLoadingError"
      :is-loading="isProgressVisible"
      :message-options="messageOptions"
      :was-read="message.wasRead"
      :error-text="$t('domains.consultation.downloading-error')"
      @onPhotoLoaded="onPhotoLoaded"
      @onDownload="onSelectOption({ key: 'Download-to-device' })"
      @onMenuSelect="onSelectOption"
      @select="onSelectOption(messageKey)"
    />
    <v-chat-message
      v-if="message.isLocalFile"
      :message-id="message.id"
      :message-url="payload.url"
      :is-incoming="message.incoming"
      :type="message.messageType"
      :time-stamp="createdAtFormatted"
      :is-preview="isPreview"
      :is-error="message.isError"
      :is-loading-error="isLoadingError"
      :is-sending="message.isSending"
      :is-single="true"
      progress-color="text-v-ui-text-monochrome-0"
      :is-loading="isProgressVisible"
      :message-options="messageOptions"
      :was-read="message.wasRead"
      :error-text="$t('domains.consultation.downloading-error')"
      @onDownload="onSelectOption({ key: 'Download-to-device' })"
      @onMenuSelect="onSelectOption"
      @select="onSelectOption(messageKey)"
    />
  </div>
</template>

<script>
import { VChatMessage } from '@doctis.front/doctis.designsystem';
import { format, parseISO } from '@evd3v/date-fns';
import {
  FILTER_FILE_MESSAGE_OPTIONS,
  FILE_MESSAGE_OPTIONS
} from '@/services/files/files.const';
import {
  downloadFile,
  filterOptions,
  imageMessageKey,
  saveOrOpenBlob
} from '@/services/files/files.helpers';

export default {
  name: 'SupportChatMessageImage',
  components: {
    VChatMessage
  },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isProgressVisible: false,
      isPreview: true,
      isError: false,
      isLoadingError: false,
      isPhotoNotLoaded: true,
      imageUrl: '',
      progressValue: 0,
      xmlHTTP: ''
    };
  },
  computed: {
    messageClass() {
      return this.message.incoming ? 'w-max' : 'justify-end';
    },
    payload() {
      return JSON.parse(this.message.payload);
    },
    createdAtFormatted() {
      if (this.message.isLocalFile)
        return format(parseISO(this.message.createdAt), 'HH:mm');
      else return this.message.time;
    },
    messageOptions() {
      return filterOptions({
        message: this.message,
        options: FILE_MESSAGE_OPTIONS,
        filterOptions: FILTER_FILE_MESSAGE_OPTIONS,
        isProgressVisible: this.isProgressVisible,
        isPreview: this.isPreview
      });
    },
    messageKey() {
      return imageMessageKey(this.message, this.isLoadingError);
    }
  },
  watch: {
    isPreview() {
      if (this.isPreview === false) {
        this.setFullImage();
      }
    },
    message() {
      if (this.message.isPreview === false) {
        this.setFullImage();
      }
    }
  },
  methods: {
    setFullImage() {
      this.isLoadingError = false;
      saveOrOpenBlob(this.message.src, this.onProgressCallback).then(
        (imgSrc) => {
          this.imageUrl = imgSrc.url;
          this.xmlHTTP = imgSrc.xmlHTTP;
          this.isError = imgSrc.isError;
          this.isProgressVisible = imgSrc.isProgressVisible;
        }
      );
    },

    onProgressCallback(ratio) {
      if (ratio === 100) return this.onPhotoLoaded;
      else if (ratio >= 0) {
        this.isProgressVisible = true;
        this.progressValue = ratio;
        this.isLoadingError = false;
      } else {
        this.progressValue = 0;
        this.isPreview = true;
        this.isProgressVisible = false;
        this.isLoadingError = true;
      }
    },
    onPhotoLoaded() {
      this.isPhotoNotLoaded = false;
      this.isProgressVisible = false;
    },
    abortDownloading() {
      this.isPreview = true;
      this.isProgressVisible = false;
      this.progressValue = 0;
      this.xmlHTTP.abort();
    },
    downloadFile() {
      const { fullBlob, title } = this.message;
      downloadFile(fullBlob, title);
    },
    onSelectOption(select) {
      if (select.key === 'Download-to-device') {
        this.downloadFile();
      } else if (select.key === 'Download') this.isPreview = false;
      else if (select.key === 'Cancel') {
        this.abortDownloading();
      } else {
        this.$emit('setMessage', {
          selected: select.key,
          messageId: this.message.id
        });
      }
    }
  }
};
</script>
