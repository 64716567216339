import ReferralConsultationsService from '@/services/referral-consultations/referral-consultations.service';

export default function({ $axiosClient, $config }, inject) {
  const client = $axiosClient.instance;

  const referralConsultationsService = new ReferralConsultationsService(
    client,
    $config
  );
  inject('referralConsultationsService', referralConsultationsService);
}
