import VarioqubService from '@/services/metrika/varioqub.service';

export default function({ $config, store }, inject) {
  const varioqubService = new VarioqubService(
    $config.APP_YANDEX_METRIKA_ID,
    store
  );
  varioqubService.getFlags();
  inject('varioqubService', varioqubService);
}
