<template>
  <div
    v-if="isNotificationVisible"
    class="sticky h-12 w-full flex items-center justify-between bg-v-ui-root-main px-4 py-2"
  >
    <v-icon name="m/cross" class="text-[#D0D2D5]" @click="close" />
    <span class="text-v-ui-text-monochrome-0 text-sm font-medium">
      {{ $t('domains.domains.application.continue') }}
    </span>
    <a
      :href="APPLICATION_LINK"
      class="flex items-center bg-v-ui-root-monochrome-0 text-v-ui-root-main h-8 px-3 rounded-full font-medium text-sm"
    >
      {{ $t('domains.domains.application.open') }}
    </a>
  </div>
</template>

<script>
import { VIcon } from '@doctis.front/doctis.designsystem';
import { APPLICATION_LINK } from '@/consts';

export default {
  name: 'VcApplicationNotification',
  components: {
    VIcon
  },
  data() {
    return {
      APPLICATION_LINK,
      isClosed: false
    };
  },
  computed: {
    isMobileDevice() {
      return this.$device.isAndroid || this.$device.isIos;
    },
    isNotificationVisible() {
      return (
        this.isMobileDevice &&
        this.$config.APP_SHOW_APPS_NOTIFY &&
        this.isClosed
      );
    }
  },
  methods: {
    close() {
      this.isClosed = true;
    }
  }
};
</script>

<style scoped></style>
