<template>
  <div class="h-full w-full">
    <support-chat-messages
      id="support-chat"
      :groups-messages="groupsMessages"
      @setMessage="setMessage"
    />
    <support-chat-input @filesCount="filesCount" />
  </div>
</template>

<script>
import SupportChatMessages from '@/components/domains/support/ui/chat/SupportChatMessages';
import SupportChatInput from '@/components/domains/support/ui/chat/SupportChatInput';

export default {
  name: 'SupportChat',
  components: {
    SupportChatInput,
    SupportChatMessages
  },
  props: {
    groupsMessages: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.setHeightChat();
    },
    filesCount(count) {
      this.$emit('filesCount', count);
    },
    setMessage(message) {
      this.$emit('setMessage', message);
    },
    setHeightChat() {
      const support = document.getElementById('vc-support').offsetHeight;
      const chat = document.getElementById('support-chat');
      const chatInput = document.getElementById('support-chat-input')
        .offsetHeight;
      const chatHeader = document.getElementById('support-chat-header')
        .offsetHeight;

      chat.style.height = `${support - chatInput - chatHeader}px`;
    }
  }
};
</script>
