<template>
  <v-chat-message :type="message.messageType" :text="message.payload" />
</template>

<script>
import { VChatMessage } from '@doctis.front/doctis.designsystem';
export default {
  name: 'SupportChatMessageTimestamp',
  components: { VChatMessage },
  props: {
    message: {
      type: Object,
      required: true
    }
  }
};
</script>
