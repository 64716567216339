export const FILE_CATEGORIES = ['image', 'audio', 'video', 'document'];

export const FILE_TYPES = {
  ANALYSIS: {
    id: 1,
    title: 'consts.file-types.analysis'
  },
  RESEARCH: {
    id: 2,
    title: 'consts.file-types.research'
  },
  INSPECTION: {
    id: 3,
    title: 'consts.file-types.inspection'
  },
  OTHER: {
    id: 4,
    title: 'consts.file-types.other'
  }
};

export const MESSAGE_OPTIONS = {
  REPEAT: {
    key: 'Repeat',
    title: 'Повторить'
  },
  COPY: {
    key: 'Copy',
    title: 'Копировать'
  },
  DELETE: {
    key: 'Delete',
    title: 'Удалить'
  }
};

export const FILE_MESSAGE_OPTIONS = {
  REPEAT: {
    key: 'Repeat',
    title: 'Повторить'
  },
  DELETE: {
    key: 'Delete',
    title: 'Удалить'
  },
  DOWNLOAD: {
    key: 'Download',
    title: 'Загрузить'
  },
  DOWNLOAD_TO_DEVICE: {
    key: 'Download-to-device',
    title: 'Скачать на устройство'
  },
  CANCEL: {
    key: 'Cancel',
    title: 'Отменить загрузку'
  }
};

export const FILTER_MESSAGE_OPTIONS = {
  isSending: { keys: ['Copy', 'Delete'] },
  isError: { keys: ['Repeat', 'Copy', 'Delete'] },
  default: { keys: ['Copy'] }
};
export const FILTER_FILE_MESSAGE_OPTIONS = {
  isSending: { keys: ['Delete'] },
  isError: { keys: ['Repeat', 'Delete'] },
  defaultPhoto: { keys: ['Download', 'Download-to-device'] },
  default: { keys: ['Download-to-device'] },
  isDownloading: { keys: ['Cancel'] }
};

export const FILE_API = 'https://fileserver.dev.doctis.app/api/v1/file/';
