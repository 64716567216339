import ChatService from '@/services/chat/chat.service';

export default function(
  { $axios, $auth, $axiosClient, $hubClient, $config },
  inject
) {
  const client = $axiosClient.instance;

  const chatService = new ChatService(client, $hubClient, $config);
  inject('chatService', chatService);
}
