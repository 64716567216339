export const POLICY_STATUS = {
  UNKNOWN: 0,
  INACTIVE: 1,
  PENDING: 2,
  ACTIVE: 3,
  EXPIRED: 4,
  TERMINATED: 5
};

export const ERROR_STATUS = {
  AGREEMENTS: 601,
  FIO: 602,
  SERVICE: 500,
  VALID_PERIOD: 603
};
