import ReferralAnalysisApi from './referral-analysis.api';
import {
  isNew,
  isTabs,
  getTypeInfoMessage,
  isAccept,
  isProvided,
  isVisibleFiles,
  isVisibleProgress,
  isDeclined
} from './referral-analysis.getters';
import {
  getPatientInfo,
  getConsultationInfo,
  getBreadcrumbs,
  getProgressInfo,
  getResultFiles
} from './referral-analysis.helpers';
import { STATUS } from './referral-analysis.const';
import { downloadFile } from '@/services/files/files.helpers';

class ReferralAnalysisService {
  constructor(app, client, $config) {
    this.app = app;
    this.api = new ReferralAnalysisApi(client, $config);
    this.referral = {};
  }

  async getReferral(id, isNewTemporaryStatus) {
    const { data } = await this.api.getReferral(id);

    // Устанавливаем фейковый статус NEW Для отображение кнопок
    if (isNewTemporaryStatus) data.status = STATUS.NEW;

    this.referral = {
      ...data,
      cost: data.order?.cost,
      patient: getPatientInfo(data.patient),
      ...getConsultationInfo(data),
      breadcrumbs: getBreadcrumbs(data.status),
      isTabs: isTabs(data),
      isNew: isNew(data),
      isAccept: isAccept(data),
      isProvided: isProvided(data),
      isDeclined: isDeclined(data),
      isVisibleFiles: isVisibleFiles(data),
      progress: getProgressInfo(data),
      infoMessage: getTypeInfoMessage(data),
      resultFiles: getResultFiles(data),
      isVisibleProgress: isVisibleProgress(data),
      isNewTemporaryStatus
    };

    return this.referral;
  }

  createOrderAnalysis() {
    this.app.router.push({
      path: '/analysis',
      query: { referralId: this.referral.id }
    });
  }

  async acceptReferral() {
    await this.api.acceptReferral(this.referral.id);
  }

  async declineReferral() {
    await this.api.declineReferral(this.referral.id);
  }

  async confirmResultsAdded() {
    await this.api.confirmResultsAdded(this.referral.id);
  }

  async uploadResult(files) {
    const formData = new FormData();
    files.forEach(({ file }, index) => {
      formData.append(`files`, file);
    });
    await this.api.uploadResult(this.referral.id, formData);
  }

  async deleteFile(fileId) {
    await this.api.deleteFile(fileId);
  }

  async downloadFile({ id, name }) {
    const file = await this.api.downloadFile(id);
    downloadFile(new Blob([file]), name);
  }

  downloadAllAnalysis() {
    for (const file of this.referral.resultFiles) {
      this.downloadFile(file);
    }
  }
}

export default ReferralAnalysisService;
