import { Api } from '~/api';

class VtbApi extends Api {
  constructor(client, $config) {
    const baseUrl = `${$config.APP_NEW_API}/`;
    super(client, baseUrl);

    this.baseURL = baseUrl;
  }

  async vtbAgreementList() {
    const { data } = await this.get('v3/vtb/agreement/list');
    return data;
  }

  async vtbAgreementGetFile(id) {
    const { data } = await this.get(`v3/vtb/agreement/file?agreementId=${id}`, {
      responseType: 'arraybuffer'
    });
    return data;
  }

  async vtbAgreementSign(agreementIds) {
    const { data } = await this.post('v3/vtb/agreement/sign', { agreementIds });
    return data;
  }

  async vtbSendFiles(formData) {
    const { data } = await this.post('policy/upload-for-moderation', formData, {
      withoutToast: true
    });
    return data;
  }
}

export default VtbApi;
