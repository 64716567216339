import PatientApi from '@/services/patient/patient.api';

class PatientService {
  constructor(client, $config, i18n) {
    this.api = new PatientApi(client, $config);
    this.$t = i18n.t.bind(i18n);
  }

  updateProfile(payload) {
    return this.api.updateProfile(payload);
  }

  async getProfile() {
    const { data } = await this.api.getProfile();
    return data;
  }

  createFamilyProfile(payload) {
    return this.api.createFamilyProfile(payload);
  }

  async getFamilyProfiles() {
    const { data } = await this.api.getFamilyProfiles();
    return data;
  }

  async sendProfileUpdateReport() {
    const { data } = await this.api.sendProfileUpdateReport();
    return data;
  }

  async getSetting() {
    const { data } = await this.api.getSetting();
    return data;
  }

  async getNotSignAgreements(params) {
    const agreements = await this.api.getNotSignAgreements(params);

    return agreements.map((agreement) => {
      return {
        ...agreement,
        value: false
      };
    });
  }

  async getAgreementsForConsultation(doctorId) {
    const agreements = await this.api.getAgreementsForConsultation(doctorId);

    return agreements.map((agreement) => {
      return {
        ...agreement,
        value: false
      };
    });
  }
}

export default PatientService;
